import { Card } from 'components/shared/Card/Card'
import { useCopyText } from 'hooks/useCopyText'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from 'styles/Markdown.module.css'
import { twJoin } from 'tailwind-merge'

export const BotCard = ({ content }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const { copyToClipboard } = useCopyText()

  return (
    <Card className="border-0 bg-violet-100">
      <div className="flex">
        <div className={twJoin('flex w-full items-start justify-between gap-4', !isExpanded && 'items-center')}>
          <div className="flex size-10 shrink-0 items-center justify-center rounded-md border bg-slate-100">
            <img src="/icons/ai.png" className="size-6" alt="response-icon" />
          </div>

          {isExpanded ? (
            <div className="grow self-center">
              <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
                {content}
              </ReactMarkdown>
            </div>
          ) : (
            <div className="text-sm text-violet-700">Click arrow icon to show content -&gt;</div>
          )}

          <div className="flex shrink-0 items-center gap-1">
            <button className="m-1" onClick={() => copyToClipboard(content)}>
              <img className="h-5 w-5" src="/icons/copy.svg" alt="copy" />
            </button>
            <button className="m-1" onClick={() => setIsExpanded(!isExpanded)}>
              <img className="h-4" src={`${isExpanded ? '/icons/down.png' : '/icons/up.png'}`} alt="toggle" />
            </button>
          </div>
        </div>
      </div>
    </Card>
  )
}
