import { postLoop, postProcessLoop } from 'api/loop/handler'
import { addData, addOutputData, setPayload } from 'app/features/loopSlice'
import { GenerateButton } from 'components/shared/Buttons'
import { Card } from 'components/shared/Card/Card'
import { Select, Textarea } from 'components/shared/Inputs'
import { DarkSpinner } from 'components/shared/Loaders'
import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const FORM_SELECT_INPUTS = {
  goal: {
    label: 'Goal',
    iconSrc: '/icons/question.png',
    iconInfoTooltip: 'The overall purpose for processing',
    options: [
      { label: 'Extract key points', value: 'TO_EXTRACT_KEY_POINTS' },
      { label: 'Explain for a non-technical audience', value: 'TO_EXPLAIN' },
      { label: 'Find specific information', value: 'TO_FIND' }
    ]
  },
  length: {
    label: 'Length',
    iconSrc: '/icons/question.png',
    iconInfoTooltip:
      'The approximate length of the result. It may vary depending on the length of the original document.',
    options: [
      { label: 'Short', value: 'SHORT' },
      { label: 'Medium', value: 'MEDIUM' },
      { label: 'Long', value: 'LONG' }
    ]
  },
  format: {
    label: 'Format',
    iconSrc: '/icons/question.png',
    iconInfoTooltip: 'The format in which the results are presented.',
    options: [
      { label: 'Tables', value: 'TABLES' },
      { label: 'Report', value: 'REPORT' },
      { label: 'Key points list', value: 'KEY_POINTS_LIST' }
    ]
  },
  language: {
    label: 'Language',
    iconSrc: '/icons/question.png',
    iconInfoTooltip: 'The language in which the results are presented.',
    options: [
      { label: 'English', value: 'ENGLISH' },
      { label: 'Spanish', value: 'SPANISH' },
      { label: 'French', value: 'FRENCH' },
      { label: 'German', value: 'GERMAN' }
    ]
  }
}

export const Form = () => {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  const { qntyFiles, files, selectedFiles } = useSelector(({ files }) => files)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (data) => {
    setIsLoading(true)
    dispatch(setPayload(data))
    const response = await dispatch(postLoop({ ...data, document: files[selectedFiles] }))

    if (response.status !== 201) {
      toast.error('Something went wrong')
    } else {
      dispatch(addData(response.data))
      const responseProcessedLoop = await dispatch(postProcessLoop(response.data.id))
      if (responseProcessedLoop.status === 200) {
        toast.success('File processed successfully')
        dispatch(addOutputData(responseProcessedLoop.data))
      } else {
        toast.error('Something went wrong')
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <DarkSpinner />
      ) : (
        <div className="pb-5">
          <Card>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="flex flex-col gap-8 text-sm">
                <div className="flex grow flex-col gap-5">
                  {Object.entries(FORM_SELECT_INPUTS).map(([key, value]) => (
                    <Select key={key} {...value} register={register(key)} />
                  ))}
                  <Textarea
                    label="Attention"
                    iconSrc="/icons/question.png"
                    iconInfoTooltip="This field is used to specify to the AI what it has to pay special attention to when processing the document and generating the results."
                    register={register('attention')}
                    className="max-h-24"
                  />
                </div>

                <div className="flex justify-end">
                  <ReactTooltip
                    enabled={qntyFiles !== 1}
                    tooltipContent={
                      qntyFiles === 0
                        ? 'You must select a file from the sidebar content list'
                        : 'You must select only one file'
                    }
                  >
                    <GenerateButton isDisabled={qntyFiles !== 1} />
                  </ReactTooltip>
                </div>
              </div>
            </form>
          </Card>
        </div>
      )}
    </>
  )
}
