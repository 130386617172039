import { API } from '../baseUrl'
export const postMirror = async (body) => await API.post(`/mirror/`, body)
export const getMirrorList = async () => await API.get(`/mirror/list/`)
export const getSingleMirror = async (id) => await API.get(`/mirror/${id}/`)
export const deleteSingleMirror = async (id) => await API.delete(`/mirror/${id}/`)
export const patchSingleMirror = async (id) => await API.patch(`/mirror/${id}/`)
export const addQuestion = async (id, body) => await API.post(`/mirror/${id}/question/`, body)
export const reprocessQuestion = async (id) => await API.patch(`/mirror/${id}/document`)
export const downloadPDF = async (id) => {
    return await API.get(`/mirror/${id}/download_pdf`, {
      responseType: 'blob'
    });
  }