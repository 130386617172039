import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pdfStatus: 'ready',
  assistantIdRedux: null,
  threadIdRedux: null,
  data: []
}

const smartChatSlice = createSlice({
  name: 'smartChat',
  initialState,
  reducers: {
    setPdfStatus(state, action) {
      state.pdfStatus = action.payload
    },
    setData(state, action) {
      state.data = action.payload
    },
    setThreadIdRedux(state, action) {
      state.threadIdRedux = action.payload
    },
    addData(state, action) {
      state.data.push(action.payload)
    },
    resetsmartchatState: () => initialState
  }
})

export const { setPdfStatus, setData, resetsmartchatState, addData, setThreadIdRedux } = smartChatSlice.actions
export default smartChatSlice.reducer
