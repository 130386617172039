import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addBookmark } from '../../../api/Bookmark/action/bookmark'

function AddBookmarkModal({ onSave, onCancel, workspace_id, workspace_type, sub_workspace_id = null }) {
  const dispatch = useDispatch()
  const [bookmarkName, setBookmarkName] = useState('')
  const [bookmarkUrl, setBookmarkUrl] = useState('')

  const handleAddBookmark = async () => {
    if (!workspace_id) {
      onSave({ data: 'please start chat befores saving bookmark', status: 400 })
      return
    }
    if (!bookmarkName.trim()) {
      onSave({ data: 'please name the bookmark', status: 400 })
      return
    }
    const response = await dispatch(addBookmark(bookmarkName, workspace_id, workspace_type, sub_workspace_id))
    onSave(response)
    setBookmarkName('')
    setBookmarkUrl('')
  }

  const handleAddBookmarkCancel = () => {
    onCancel()
    setBookmarkName('')
    setBookmarkUrl('')
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-[26rem]">
          <div className="border-0 rounded-lg shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex w-full items-start justify-between px-5 pt-3 border-b border-solid border-blueGray-200 rounded-t">
              <h5 className="text-gray-600 m-2 big">Add Bookmark</h5>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleAddBookmarkCancel}
              >
                <span className="bg-transparent text-black text-2xl block outline-none focus:outline-none">×</span>
              </button>
            </div>
            <div className="w-full p-6">
              <p className="big ml-2">Name:</p>
              <input
                type="text"
                value={bookmarkName}
                onChange={(e) => setBookmarkName(e.target.value)}
                placeholder="Enter Name"
                className="w-full small px-4 py-2 m-2 border rounded"
              />
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                onClick={handleAddBookmarkCancel}
                className="py-1 font-semibold px-5 m-2 border rounded big cursor-pointer"
              >
                Cancel
              </button>
              <button
                onClick={handleAddBookmark}
                className="py-1 px-5 font-semibold m-2 text-white bg-blue-600 rounded big cursor-pointer"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default AddBookmarkModal
