import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { editText } from '../../../app/features/counterSlice'
import {
  addPreset as addSmartChatPreset,
  deletePreset as deleteSmartChatPreset,
  togglePresetSelection as toggleSmartChatPresetSelection
} from '../../../app/features/smartchattaskslice'
import {
  addPreset as addKnowledgePreset,
  deletePreset as deleteKnowledgePreset,
  togglePresetSelection as toggleKnowledgePresetSelection
} from '../../../app/features/knowledgetaskslice'
import {
  addPreset as addMirrorPreset,
  deletePreset as deleteMirrorPreset,
  togglePresetSelection as toggleMirrorPresetSelection
} from '../../../app/features/mirrortaskslice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LOOP, ASSISTANT, MIRROR } from '../../../lib/constants/BookMarkConst'
// import AddPresetModal from './AddPresetModal'; // Make sure the path is correct
import { addTask, deleteTask, getTaskList } from '../../../api/Task/action/action'

function Presets() {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [toggle, setToggle] = useState(false)

  const handleChange = (text) => {
    dispatch(editText(text))
  }

  let slice
  let addAction, deleteAction, toggleAction
  let workspace_selected
  const smartloc = useSelector((state) => state.smartChattask)
  const knowledgeloc = useSelector((state) => state.knowledgetask)
  const mirrorloc = useSelector((state) => state.mirrortask)

  if (pathname === '/playground/assistant' || pathname === '/playground') {
    slice = smartloc
    workspace_selected = ASSISTANT
    addAction = addSmartChatPreset
    deleteAction = deleteSmartChatPreset
    toggleAction = toggleSmartChatPresetSelection
  } else if (pathname === '/playground/loop') {
    slice = knowledgeloc
    workspace_selected = LOOP
    addAction = addKnowledgePreset
    deleteAction = deleteKnowledgePreset
    toggleAction = toggleKnowledgePresetSelection
  } else if (pathname === '/playground/mirror') {
    slice = mirrorloc
    workspace_selected = MIRROR
    addAction = addMirrorPreset
    deleteAction = deleteMirrorPreset
    toggleAction = toggleMirrorPresetSelection
  }

  const [showAddPresetModal, setShowAddPresetModal] = useState(false)

  const handleAddPresetCancel = () => {
    setShowAddPresetModal(false)
  }

  const handleDeletePreset = async (id) => {
    const response = await dispatch(deleteTask(id))
    if (response.status !== 204) return toast.error("Couldn't delete it right now")
    toast.success('deleted Item successfully')
    setToggle(!toggle)
  }

  const handleAddPreset = () => {
    setToggle(!toggle)
    setShowAddPresetModal(false)
  }

  const togglePresetSelection = (index) => {
    dispatch(toggleAction(index))
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getTaskList())
      if (response.status === 200) {
        const tasksWithIsSelected = response.data.tasks
          .filter((task) => task.workspace === workspace_selected)
          .map((task) => ({
            ...task,
            selected: false
          }))

        // Dispatch the modified tasks
        dispatch(addAction(tasksWithIsSelected))
        return
      }
    }
    // setWorkspace(workspace_selected)
    fetchData()
  }, [toggle])

  return (
    <>
      {showAddPresetModal && (
        <AddPresetModal onSave={handleAddPreset} onCancel={handleAddPresetCancel} workspace={workspace_selected} />
      )}

      <div className="mb-2 flex justify-between">
        <div className="flex items-center gap-1">
          <img src="/icons/down.png" alt="logo" className="h-4 w-4 object-cover" />
          <p className="big">Tasks</p>
        </div>
        <button onClick={() => setShowAddPresetModal(true)} className="small cursor-pointer text-[#3163E4]">
          + Add
        </button>
      </div>

      <div className="flex h-[12rem] max-h-[12rem] flex-col overflow-y-auto py-2">
        {slice && slice.presets && slice.presets.length > 0 ? (
          slice.presets.map((preset, index) => (
            <div
              key={index}
              className={`my-1 mr-2 flex items-center rounded p-2 text-sm hover:bg-blue-100 ${
                preset.selected ? 'bg-[#E6EDFF]' : ' bg-[#FBFBFB] hover:bg-[#E6EDFF]'
              } group`}
            >
              <div
                className="flex w-full cursor-pointer flex-col items-start justify-start"
                onClick={() => {
                  togglePresetSelection(index)
                  handleChange(preset.data)
                }}
              >
                <p className="big text-[#383838]">{preset.title}</p>
                <p className="small  text-[#6C6C6C]">{preset.data}</p>
              </div>
              <button
                className="ml-auto flex items-center justify-center opacity-0 group-hover:opacity-100"
                onClick={async (e) => {
                  e.stopPropagation()
                  handleDeletePreset(preset.id)
                }}
              >
                <img className="h-4 w-4" src="/icons/bin.png" alt="Bin" />
              </button>
            </div>
          ))
        ) : (
          <div className="small ">No presets available</div>
        )}
      </div>
    </>
  )
}

export default Presets

// import React, { useState } from 'react';

function AddPresetModal({ onSave, onCancel, workspace }) {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')
  const [textFieldValue, setTextFieldValue] = useState('')

  const handleAddPreset = async () => {
    if (!inputValue.trim()) {
      // alert("Both fields are required.");
      toast.error('Please Fill Title')
      return
    }
    if (!textFieldValue.trim()) {
      // alert("Both fields are required.");
      toast.error('Please Fill Instruction')
      return
    }
    const response = await dispatch(addTask(inputValue, textFieldValue, workspace))

    if (!response.status || response.status > 201) {
      toast.error(response.data)
      return
    }
    toast.success('Task Added Successfully')
    onSave()
    setInputValue('')
    setTextFieldValue('')
  }

  const handleAddPresetCancel = () => {
    onCancel()
    setInputValue('')
    setTextFieldValue('')
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-[26rem]">
          <div className="flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
            <div className="border-blueGray-200 flex w-full items-start justify-between rounded-t border-b border-solid px-5 pt-3">
              <h5 className="big m-2 text-gray-600">Add Task</h5>
              <button
                className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                onClick={handleAddPresetCancel}
              >
                <span className="block bg-transparent text-2xl text-black outline-none focus:outline-none">×</span>
              </button>
            </div>
            <div className="w-full p-6">
              <p className="big ml-2">Title:</p>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter Title"
                className="small m-2 w-full rounded border px-4 py-2"
              />
              <p className="big ml-2">Instruction:</p>
              <textarea
                value={textFieldValue}
                onChange={(e) => setTextFieldValue(e.target.value)}
                placeholder="Enter Instruction"
                className="small m-2 w-full rounded border px-4 py-2"
                rows="4"
              />
            </div>
            <div className="border-blueGray-200 flex items-center justify-end rounded-b border-t border-solid p-6">
              <button
                onClick={handleAddPresetCancel}
                className="big m-2 cursor-pointer rounded border px-5 py-1 font-semibold hover:bg-blue-100"
              >
                Cancel
              </button>
              <button
                onClick={handleAddPreset}
                className="big m-2 cursor-pointer rounded bg-blue-500 px-5 py-1 font-semibold text-white hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  )
}

// export default AddPresetModal;
