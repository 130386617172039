import { ProgressBar } from 'components/shared/Loaders/ProgressBar'

export const ModalHeader = ({ onClose, title, isLoading }) => {
  return (
    <div>
      <div className="flex w-full items-center justify-between border-b px-4 py-2">
        <h5 className="text-sm font-semibold text-gray-500">{title}</h5>
        {!isLoading && (
          <button className="border-0 text-xl text-gray-500 hover:text-black" onClick={() => onClose()}>
            ×
          </button>
        )}
      </div>
      {isLoading && <ProgressBar />}
    </div>
  )
}
