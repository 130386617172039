import * as api from '../index.js'
export const login = (body) => async (dispatch) => {
  try {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    const response = await api.login(body)
    if (response.status < 300) {
      localStorage.setItem('access_token', JSON.stringify(response.data['access_token']))
      localStorage.setItem('refresh_token', JSON.stringify(response.data['refresh_token']))
      return { status: response.status, message: 'Login Successfull' }
    }
  } catch (error) {
    if (error.response) return { message: error.response.data.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { message: e.message, status: e.error_code ?? 400 }
  }
}
export const refresh = (body) => async (dispatch) => {
  try {
    const response = await api.refresh(body)
    return response
  } catch (error) {
    return error
  }
}
export const logout = (body) => async (dispatch) => {
  try {
    const response = await api.refresh(body)
    if (response.status <= 400) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}
