import { API } from '../baseUrl'
export const documentList = async () => await API.get(`/document/list/`)
export const documentDownload = async (body) => {
  const response = await API.get(`/document/${body.id}/download`, {
    responseType: 'blob'
  })
  return response
}
export const documentUpload = async (body) => await API.post(`/document/`, body)
export const documentDelete = async (body) => await API.delete(`/document/${body.id}/`)
