// ExtractorBookmarkOutput.jsx
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from '../../../styles/Markdown.module.css'
import { useCopyText } from '../../../hooks/useCopyText'

const ExtractorBookmarkOutput = ({ data }) => {
  const [showOriginalText, setShowOriginalText] = useState(true)
  const [showGeneratedText, setShowGeneratedText] = useState(true)
  const { copyToClipboard } = useCopyText()

  return (
    <div className="flex flex-col items-center  mt-7 h-full w-full">
      {/* Original Text Section */}
      <div className="max-h-64 text-[#575757] mb-4">
        <div className="p-2 mx-3 border rounded-lg bg-white" style={{ width: '450px' }}>
          <div className="flex justify-between my-2">
            <div className="flex items-center gap-1">
              <button className="bg-gray-100 border py-1 px-1 m-1 rounded-lg">
                <img className="w-6" src="/icons/user.png" alt="logo" />
              </button>
              <p className="font-bold big">Original Text</p>
            </div>
            <div className="flex items-center gap-1">
              <button
                className="m-1"
                onClick={() => copyToClipboard(data.map((item) => item.sections.data).join('\n'))}
              >
                <img className="h-5 w-5" src="/icons/copy.png" alt="Copy" />
              </button>
              <button className="m-1" onClick={() => setShowOriginalText(!showOriginalText)}>
                {showOriginalText ? (
                  <img className="h-5 w-5" src="/icons/up.png" alt="Hide" />
                ) : (
                  <img className="h-5 w-5" src="/icons/down.png" alt="Show" />
                )}
              </button>
            </div>
          </div>
          {showOriginalText && (
            <div className="max-h-[200px] overflow-y-auto">
              <p className="small wrapped-text">
                <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
                  {data.instructions}
                </ReactMarkdown>
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Generated Text Section */}
      <div className="max-h-64 text-[#5F5978] mb-4">
        <div className="p-2 mx-3 border rounded-lg bg-blue-50" style={{ width: '450px' }}>
          <div className="flex justify-between my-2">
            <div className="flex items-center gap-1">
              <button className="bg-gray-100 border py-1 px-1 m-1 rounded-lg">
                <img className="w-6" src="/icons/ai.png" alt="logo" />
              </button>
              <p className="font-bold big">AI Generated</p>
            </div>
            <div className="flex items-center gap-1">
              <button className="m-1" onClick={() => copyToClipboard(data.map((item) => item.data).join('\n'))}>
                <img className="h-5 w-5" src="/icons/copy.png" alt="Copy" />
              </button>
              <button className="m-1" onClick={() => setShowGeneratedText(!showGeneratedText)}>
                {showGeneratedText ? (
                  <img className="h-5 w-5" src="/icons/up.png" alt="Hide" />
                ) : (
                  <img className="h-5 w-5" src="/icons/down.png" alt="Show" />
                )}
              </button>
            </div>
          </div>
          {showGeneratedText && (
            <div className="max-h-[200px] overflow-y-auto">
              {data?.sections.map((item, index) => (
                <p key={index} className="small wrapped-text">
                  <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
                    {item.data}
                  </ReactMarkdown>
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExtractorBookmarkOutput
