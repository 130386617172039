import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { twMerge } from 'tailwind-merge'
import { twJoin } from 'tailwind-merge'

export const Textarea = ({ label = '', iconSrc = '', className, iconInfoTooltip = false, register = {} }) => {
  return (
    <div className={twJoin(!!label && 'flex flex-col gap-2')}>
      {!!label && (
        <label className="flex gap-1">
          <span>{label}</span>
          <ReactTooltip tooltipContent={iconInfoTooltip} place="top" enabled={!!iconInfoTooltip}>
            {!!iconSrc && <img src={iconSrc} alt={iconInfoTooltip} />}
          </ReactTooltip>
        </label>
      )}

      <textarea className={twMerge('w-full rounded-md bg-gray-100 p-3', className)} {...register} />
    </div>
  )
}
