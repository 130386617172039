import * as api from '../index.js'

export const createThread = (documentIds, name) => async (dispatch) => {
  try {
    const data = {
      name: name ? name : 'Thread ' + new Date().toLocaleString(),
      ...(documentIds && { document_ids: documentIds })
    }

    const response = await api.createThread(data)
    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const getSingleThread = (threadId) => async (dispatch) => {
  try {
    const response = await api.getSingleThread(threadId)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const askQuestion =
  (threadId, message, documents = []) =>
  async (dispatch) => {
    try {
      const data = {
        data: message,
        ...(documents.length > 0 && { document_ids: documents })
      }

      const response = await api.askQuestion(threadId, data)
      if (response.status < 300) return { status: response.status, data: response.data }
    } catch (error) {
      if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

      const e = error.toJSON()
      return { data: e.message, status: e.error_code ?? 400 }
    }
  }

export const getThreadMessages = (threadId) => async (dispatch) => {
  try {
    const response = await api.getThreadMessages(threadId)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const downloadThreadPDF = (threadId) => async (dispatch) => {
  try {
    const response = await api.downloadThreadPDF(threadId, { responseType: 'blob' });

    if (response.status < 300) {
      return { status: response.status, blob: response.data };
    } else {
      return { status: response.status, error: "Failed to download PDF" };
    }
  } catch (error) {
    if (error.response) {
      return { error: error.response.data.message ?? error.message, status: error.response.status ?? 400 };
    } else {
      const e = error.toJSON ? error.toJSON() : { message: error.message, error_code: 400 };
      return { error: e.message, status: e.error_code };
    }
  }
}


export const addThreadDocument = (threadId, documentId) => async (dispatch) => {
  try {
    const response = await api.addThreadDocument(threadId, documentId)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const removeThreadDocument = (threadId, documentId) => async (dispatch) => {
  try {
    const response = await api.removeThreadDocument(threadId, documentId)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
