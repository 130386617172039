import 'react-toastify/dist/ReactToastify.css'
import React, { useState, useRef, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { twMerge } from 'tailwind-merge'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { setShowMessageMirror } from '../../../../app/features/fileSlice'
import { MIRROR } from '../../../../lib/constants/BookMarkConst'
import {
  setIsGenerated,
  setIsLoading,
  setStop,
  setIsListVisible,
  setTasks,
  addTask,
  removeTask,
  addGeneratedResponse,
  setMirrorIdRedux
} from '../../../../app/features/mirrorSlice' // Adjust the path as needed
import { setPdfStatus } from '../../../../app/features/mirrorSlice'
import { postMirror, addQuestion, downloadPDF} from '../../../../api/Mirror/action/action'
import { arraysAreEqual } from '../../../../api/Mirror/action/check'

import styles from '../../../../styles/Markdown.module.css'
import AddBookmarkModal from '../../Usable/AddBookMarkModel'
import { toggleOffPresetSelection } from '../../../../app/features/mirrortaskslice'
import { DefaultButton } from '../../Buttons/DefaultButton'
import { AnswerList } from './AnswerList'

export const TaskManager = () => {
  const dispatch = useDispatch()
  const { pdfStatus, mirrorIdRedux } = useSelector((state) => state.mirror)
  const [isFirstPost, setIsFirstPost] = useState(true)
  const [mirrorId, setMirrorId] = useState(null)
  const [selectedFileDuplicate, setSelectedFileDuplicate] = useState([])
  const { selectedFiles, files } = useSelector((state) => state.files)

  const handleClick = async (buttonId) => {
    dispatch(setIsGenerated(false))
  
    if (buttonId === 'generatePdf') {
      dispatch(setPdfStatus('generating'));
  
      try {
        const response = await dispatch(downloadPDF(mirrorId));
  
        if (response.blob) {
          dispatch(setPdfStatus('downloaded'));
  
          const downloadUrl = window.URL.createObjectURL(response.blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          const date = new Date();
          const formattedDate = `${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}`;
          link.download = `Mirror_${formattedDate}.pdf`;
          link.click();
  
          window.URL.revokeObjectURL(downloadUrl);
        } else {
          console.error('Failed to download PDF:', response.error || 'Unknown error');
          dispatch(setPdfStatus('error'));
        }
      } catch (error) {
        console.error('Error downloading the PDF:', error);
        dispatch(setPdfStatus('error'));
      }
    }
  };

  const { tasks, isListVisible, isGenerated, isLoading, stop } = useSelector((state) => state.mirror)
  const qntyFilesRedux = useSelector((state) => state.files.qntyFiles)
  const [showAddBookMarkModal, setShowAddBookMarkModal] = useState(false)

  const inputValueRef = useRef(null) // Initialize with null

  const taskListRef = useRef(null)
  const lastTaskRef = useRef(null)

  const handleAddTask = () => {
    const inputValue = inputValueRef.current?.value && inputValueRef.current.value.trim()
    if (inputValue !== '') {
      dispatch(addTask({ data: inputValue, id: 'no-id' })) // Dispatch action to add task
      inputValueRef.current.value = '' // Clear input
      dispatch(setIsListVisible(true)) // Set list visible
    }
  }
  const [inputValue, setInputValue] = useState('')

  const handleRemoveTask = (index, id) => {
    if (id == 'no-id') {
      dispatch(removeTask(index))
      return
    }
    dispatch(removeTask(index))
    dispatch(toggleOffPresetSelection(id))
    // console.log(index);
  }

  const handleToggleList = () => {
    if (tasks.length > 0) {
      dispatch(setIsListVisible(!isListVisible)) // Dispatch action to toggle list visibility
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddTask()
      setInputValue('')
    }
  }

  const handleShowMessageMirror = (x) => {
    dispatch(setShowMessageMirror(x))
  }
  const postData = async () => {
    var localMirrorId = mirrorId
    const selectedFilesToUpload = selectedFiles.map((index) => files[index])
    if (isFirstPost || !arraysAreEqual(selectedFileDuplicate, selectedFilesToUpload)) {
      const response = await dispatch(postMirror(undefined, files[selectedFiles[0]], files[selectedFiles[1]]))

      if (response.status === null || response.status > 201) {
        toast.error(response.data)
        return
      }
      localMirrorId = response.data.id
      setMirrorId(response.data.id)
      dispatch(setMirrorIdRedux(response.data.id))
      setIsFirstPost(false)
      setSelectedFileDuplicate([...selectedFilesToUpload])
    }
    if (localMirrorId == null) return

    const taskPromises = tasks.map(async (task) => {
      const response = await dispatch(addQuestion(localMirrorId, task.data))
      if (!response || response.status > 201) {
        toast.error(response.data)
        return null
      }
      return response
    })
    const responses = await Promise.all(taskPromises)
    responses.forEach((response) => {
      if (response) {
        dispatch(addGeneratedResponse(response.data))
      }
    })
  }

  const handleGenerate = async () => {
    if (tasks.length) {
      if (qntyFilesRedux === 2) {
        dispatch(setIsLoading(true))
        dispatch(setIsGenerated(false))
        dispatch(setStop(false))
        await postData()
        dispatch(setPdfStatus(''))
        handleShowMessageMirror(false)
        dispatch(setIsLoading(false))
        dispatch(setIsGenerated(true))
        dispatch(setTasks([]))
        dispatch(setIsListVisible(false)) // Dispatch action to hide the list
        setTimeout(() => {
          setInputValue('')
          dispatch(setIsGenerated(true))
          dispatch(setIsLoading(false))
        }, 3000)
      } else {
        handleShowMessageMirror(true)
      }
    }
  }

  const handleStop = () => {
    dispatch(setStop(true))
    dispatch(setIsLoading(false))
  }
  const handleAddBookMark = (response) => {
    if (response.status <= 201) {
      toast.success('bookmark added successfully')
      setShowAddBookMarkModal(false)
      return
    }
    toast.error(response.data)
  }
  const handleAddBookMarkCancel = () => {
    setShowAddBookMarkModal(false)
  }
  const { presets } = useSelector((state) => state.mirrortask)
  // const [syncWithRedux, setSyncWithRedux] = useState(true);
  useEffect(() => {
    console.log(presets)
    const selectedPreset = presets.filter((preset) => preset.selected)

    console.log(selectedPreset)
    selectedPreset.forEach((i) => {
      if (!tasks.includes(i)) {
        dispatch(addTask(i))
      }
    })
    dispatch(setIsListVisible(true))
  }, [presets])

  useEffect(() => {
    if (isListVisible && tasks.length > 0) {
      lastTaskRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
    if (tasks.length === 0) {
      dispatch(setIsListVisible(false))
    }
  }, [tasks, isListVisible])

  const getIconButton = () =>
    pdfStatus === 'generating' ? (
      <div
        className="text-blue-700 inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    ) : (
      <img className="h-5" src="/icons/download.png" alt="generate-pdf" />
    )
  const isDisabledButton = tasks.length === 0

  return (
    <>
      {showAddBookMarkModal && (
        <AddBookmarkModal
          onSave={handleAddBookMark}
          onCancel={handleAddBookMarkCancel}
          workspace_id={mirrorIdRedux}
          workspace_type={MIRROR}
        />
      )}

      <div className="flex w-full overflow-y-auto pt-2 justify-center h-[54.5vh]">
        {isLoading && !stop && (
          <div className="flex flex-col items-center justify-center">
            <div>
              <div
                className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
            <h1 className="font-bold text-lg"> Processing Data </h1>
            <h4 className="text-gray-400">Please be patient as we are generating the Data.</h4>
            <h4 className="text-gray-400">This may take some time</h4>
          </div>
        )}
        {pdfStatus === 'generating' && (
          <>
            <div className="flex flex-col items-center justify-center">
              <div>
                <div
                  className="inline-block text-black h-10 w-10 mx-1 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
              <h1 className="font-bold text-lg"> Generating PDF </h1>
              <h4 className="text-gray-400">Please be patient as we are generating the</h4>
              <h4 className="text-gray-400">PDF. This may take some time</h4>
            </div>
          </>
        )}
        {pdfStatus === 'downloaded' && (
          //loading download
          //  <>
          //     <div className="flex flex-col items-center justify-center">
          //         <div className="h-10 w-10 mb-1 "></div>
          //         <h1 className="font-bold text-lg"> PDF AnswerList Successfully</h1>
          //         <h4 className="text-gray-400">You can download the pdf from the</h4>
          //         <h4 className="text-gray-400">'Download PDF' button below</h4>
          //     </div>
          // </>
          <AnswerList />
        )}

        {isGenerated && !stop && <AnswerList />}
      </div>
      <div className="flex flex-column items-center justify-center mt-[48px]">
        <div className={`w-5/6 ${isListVisible ? 'border bg-white rounded-lg' : 'no-border'}`}>
          <div className="flex flex-col items-left m-1 mb-[8px] mx-9 mt-2">
            {!isListVisible && (
              <>
                <div className="mb-6">
                  <div className="mt-[16px] flex items-center justify-center gap-2">
                    <DefaultButton onClick={() => handleClick('generatePdf')} disabled={pdfStatus === 'generating'}>
                      <div className="flex justify-center items-center gap-2">
                        {getIconButton()}
                        <span className={`big ${pdfStatus === 'generating' ? ' text-blue-700' : 'text-[#555555]'}`}>
                          {pdfStatus === 'generating' ? 'Generating PDF' : 'Generate PDF'}
                        </span>
                      </div>
                    </DefaultButton>

                    <DefaultButton
                      onClick={() => setShowAddBookMarkModal(!showAddBookMarkModal)}
                      disabled={pdfStatus === 'generating'}
                      label="Bookmark"
                      icon="/icons/bookmark.svg"
                    >
                      <div className="flex justify-center items-center gap-2">
                        <img className="h-5" src="/icons/bookmark.svg" alt="logo" />
                        <p className="big text-[#555555]">Bookmark</p>
                      </div>
                    </DefaultButton>
                  </div>
                </div>
              </>
            )}

            {/* {isListVisible && (
                              <div style={{ height: '12vh',width:'400px'  ,overflowY: 'auto' }}>
                                  <div ref={taskListRef} className="border-gray-300 w-full rounded px-4  mb-4">
                                      {tasks.map((task, index) => (
                                          <div
                                              className="flex w-[38rem] rounded-md bg-gray-50  small justify-between items-center flex-wrap p-2 mb-1"
                                              key={index}
                                              ref={index === tasks.length - 1 ? lastTaskRef : null}
                                          >
                                              <div className="">
                                                  <span className="flex  wra    cursor-pointer">
                                                      <p className="wrapped-text">{task} </p>
                                                  </span>
                                              </div>
                                              <div className="">
                                                  <button
                                                      onClick={() => handleRemoveTask(index)}
                                                      className="text-gray-700"
                                                  >
                                                    
                                                  </button>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                              </div>
                          )} */}

            {isListVisible && (
              <div className="overflow-y-auto h-[78px]">
                {tasks.map((task, index) => (
                  <div key={index} ref={index === tasks.length - 1 ? lastTaskRef : null}>
                    <div className="w-full overflow-auto">
                      <div className="m-1 mx-2 bg-gray-50 rounded-md">
                        <div className="flex justify-between">
                          <div className="mr-2 p-1 overflow-hidden">
                            <p className="small wrapped-text">{task.data}</p>
                          </div>

                          <button className="bg-gray-50 mr-2 rounded-lg">
                            <img
                              className="h-3 w-3"
                              src="/icons/x.png"
                              alt="logo"
                              onClick={() => handleRemoveTask(index, task.id)}
                              style={{ minWidth: 'auto' }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div
              className={`justify-center  pl-3 items-center w-full flex rounded-lg shadow-md ${
                isListVisible ? 'no-border shadow-none' : 'border'
              }`}
            >
              <input
                ref={inputValueRef} // Assign ref to input element
                className="w-full small my-2 mr-2 ml-1 rounded-md pr-2 py-2 focus:outline-none active:no-border"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="How can I help you?"
              />
              <button className="h-4 w-4 m-1 mr-2" onClick={handleToggleList} disabled={tasks.length === 0}>
                <img src="/icons/full.png" className="bg-white" alt="toggle list visibility" />
              </button>

              <div className="flex items-center justify-between">
                <button onClick={isLoading ? handleStop : handleGenerate}>
                  <div
                    className={twMerge(
                      'py-2 px-5 flex align-center justify-center rounded-md mr-2 my-2',
                      isDisabledButton
                        ? 'bg-gray-100 text-gray-400'
                        : 'bg-gradient-to-r from-purple-500 to-purple-700 text-white'
                    )}
                  >
                    <img
                      className="h-4 w-4 mr-1"
                      src={`${isDisabledButton ? '/icons/grayStar' : '/icons/whiteStar'}.png`}
                      alt="Generate"
                    />
                    <p className="ml-1 big">{isLoading ? 'Stop' : 'Generate'}</p>
                  </div>
                </button>
              </div>
            </div>

            {/*             <TextFieldMulti
              ref={inputValueRef}
              value={inputValue}
              tasks={tasks}
              isListVisible={isListVisible}
              isLoading={isLoading}
              handleOnSubmit={handleGenerate}
              handleStop={handleStop}
              handleToggleList={handleToggleList}
              handleKeyPress={handleKeyPress}
              setInputValue={setInputValue}
            /> */}
          </div>
        </div>
      </div>
    </>
  )
}

/* const AnswerList = () => {
  const { tasks, generatedResponses } = useSelector((state) => state.mirror)
  const [isShowingContent, setIsShowingContent] = useState(true)

  const toggleVisibility = (id, isVisible) => {
    const section = document.getElementById(id)
    section.style.display = isVisible ? 'initial' : 'none'
  }

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // alert('Text copied to clipboard')
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  const handleDropdown = (taskId) => {
    //revisar si a lo mejor debería instancia useEffect en 0 : true, como objeto y usar como id de la imagen el index ,para que ambos sean 0
    setIsShowingContent((prev) => ({ ...prev, [taskId]: !prev[taskId] }))

    toggleVisibility(taskId, !isShowingContent[taskId])
  }

  console.log(isShowingContent)
  return (
    <div className="text-[#5F5978] h-full w-full">
      {generatedResponses.map((task, index) => (
        <div key={index} className="w-full px-5 mb-2">
          <div className="px-4 py-2 overflow-y-auto border mb-4 border-[#E7E7E7] rounded-lg bg-[#F6F4FF]">
            <div className="flex justify-between">
              <div className="flex items-center gap-1">
                <h1 className="text-[14px] text-[#5F5978] text-pretty font-semibold">{task.data}</h1>
              </div>
              <div className="flex items-center gap-1">
                <button className="m-1" onClick={() => copyToClipboard(task.response)}>
                  <img className="w-5 h-5" src="/icons/copy.svg" alt="copy" />
                </button>
                <button className="m-1" onClick={() => handleDropdown(task.id)}>
                  <img
                    key={task.id}
                    className="h-4"
                    src={`${isShowingContent[task.id] ? '/icons/up.png' : '/icons/down.png'}`}
                    alt="toggle"
                  />
                </button>
              </div>
            </div>
            <div id={task.id} className="small">
              <div className="pb-2 text-[13px]">
                <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
                  {task.response}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
} */
