import { useState } from 'react'
import { toast } from 'react-toastify'
import { twJoin, twMerge } from 'tailwind-merge'
import { DefaultModal } from './DefaultModal'

const SOURCE_TYPE = {
  FILE_UPLOAD: 'FILE_UPLOAD',
  URL: 'URL'
}

export const UploadModal = ({ handleFileUpload, setShowModal, isLoading, isVisible }) => {
  const [dragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [loadingSpiral, setLoadingSpiral] = useState(true)
  const [sourceType, setSourceType] = useState(SOURCE_TYPE.FILE_UPLOAD)

  const handleSourceTypeChange = (event) => {
    setSourceType(event.target.value)
    setUploadedFileName('')
    setFile('')
    setFileName('')
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    setFileName('')
    setErrorMessage('')
    const files = e.dataTransfer.files
    if (files && files.length > 0) {
      setUploadedFileName(files[0].name)

      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        if (!files[0].name) {
          setErrorMessage('Incompatible document!')
        } else {
          setFileName(files[0].name)
        }
      }, 3000)
    }
  }

  const handleFileUploadWithLoading = (event) => {
    setErrorMessage('')
    setFileName('')
    setLoading(true)
    setTimeout(() => {
      if (sourceType === SOURCE_TYPE.FILE_UPLOAD) {
        setLoading(false)
        setFile(event.target.files[0])
        setUploadedFileName(event.target.files[0].name)
        setFileName(event.target.files[0].name)
      } else if (sourceType === SOURCE_TYPE.URL) {
        setLoading(false)
        setFile(event.target.value)
        setUploadedFileName(event.target.value)
        setFileName(event.target.value)
      }
    }, 1000)
  }
  const handleProcess = () => {
    setErrorMessage('')
    setFileName('')
    if (!uploadedFileName) {
      // logic for the incompatible file
      // if (!uploadedFileName) {
      //     setLoading(true)
      //     setFileName('')
      //     setLoadingSpiral(false)
      //     setTimeout(() => {
      //         setLoading(false)
      //         setLoadingSpiral(true)
      //         setErrorMessage('Incompatible document!')
      //     }, 3000)
    } else {
      setLoadingSpiral(false)
      setLoading(true)
      setFileName('')
      setTimeout(() => {
        setLoadingSpiral(true)
        setFileName('')
        setLoading(false)
        handleFileUpload(sourceType === SOURCE_TYPE.FILE_UPLOAD ? file : uploadedFileName, sourceType, (response) => {
          if (response.status === 201 && !response.error && response.data.id) {
            setLoading(false)
            toast.success('File processed successfully')
          } else {
            toast.error('Error processing file. Please try again')
            setErrorMessage('Error')
            setLoading(false)
          }
        })
        setUploadedFileName('') // Reset uploadedFileName after processing
      }, 3000)
    }
  }

  const isProcessDisabled =
    (sourceType === SOURCE_TYPE.FILE_UPLOAD && !file && !uploadedFileName) ||
    (sourceType === SOURCE_TYPE.URL && !uploadedFileName)

  return (
    <DefaultModal
      className={twMerge(sourceType === SOURCE_TYPE.FILE_UPLOAD ? 'h-72' : 'h-48')}
      isVisible={isVisible}
      title="Content Upload"
      onClose={() => setShowModal(false)}
      onSubmit={handleProcess}
      isDisabled={isProcessDisabled || isLoading || loading}
      isLoading={isLoading || loading}
    >
      {loadingSpiral && !isLoading ? (
        <>
          <div
            className={twJoin(
              'flex justify-between gap-6',
              sourceType === SOURCE_TYPE.FILE_UPLOAD ? 'items-center' : 'items-end'
            )}
          >
            {sourceType === SOURCE_TYPE.FILE_UPLOAD ? (
              <label
                htmlFor="dropzone-file"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                className="flex h-36 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-blue-50"
              >
                <div className="flex flex-col items-center justify-center">
                  <svg
                    className="mb-4 h-8 w-8 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <div className="small align-content-center w-[400px] justify-center text-center text-gray-500 ">
                    {fileName !== '' ? (
                      <p className="wrapped-text">{fileName}</p>
                    ) : (
                      <p>
                        <span className="font-semibold">Click to upload</span> or drag and drop{' '}
                      </p>
                    )}
                  </div>
                </div>
                <input
                  onChange={(e) => handleFileUploadWithLoading(e)}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </label>
            ) : (
              <input
                type="text"
                placeholder="Enter URL to start the process"
                className="h-7 w-full flex-1 rounded-md border border-gray-300 indent-2 text-xs"
                onChange={(e) => {
                  setUploadedFileName(e.target.value)
                }}
              />
            )}
            <div className="w-44">
              <h1 className="big text-gray-600">Source Selection</h1>
              <select
                className="small mt-2 w-full cursor-pointer rounded-md border border-gray-300 p-1 text-gray-500"
                value={sourceType}
                onChange={handleSourceTypeChange}
              >
                <option value={SOURCE_TYPE.FILE_UPLOAD}>File</option>
                <option value={SOURCE_TYPE.URL}>URL</option>
              </select>
            </div>
          </div>
        </>
      ) : (
        <div className="flex grow flex-col items-center justify-center">
          <h1 className="text-lg font-bold">Processing... </h1>
        </div>
      )}
    </DefaultModal>
  )
}
