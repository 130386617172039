import { twJoin } from 'tailwind-merge'

export const ModalFooter = ({ onClose, onSubmit, isLoading, isDisabled }) => {
  return (
    <div className="mt-2 flex items-center justify-end px-4 pb-3">
      <button
        onClick={() => onClose()}
        className={twJoin(
          'big m-2 rounded border px-5 py-1 font-semibold hover:bg-blue-100',
          isLoading && 'cursor-not-allowed opacity-50 hover:bg-transparent'
        )}
        disabled={isLoading}
      >
        Cancel
      </button>
      {onSubmit && (
        <button
          onClick={() => onSubmit()}
          className={twJoin(
            'big m-2 rounded bg-blue-600 px-5 py-1  font-semibold text-white hover:bg-blue-500',
            isDisabled ? 'cursor-not-allowed opacity-50 hover:bg-blue-600' : 'cursor-pointer'
          )}
          disabled={isDisabled}
        >
          Confirm
        </button>
      )}
    </div>
  )
}
