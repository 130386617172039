import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setShowMessageLoop } from 'app/features/fileSlice'

import { getDocuments, uploadDocument } from 'api/Document/action/document'
import { addFile, setFile, toggleFileSelection } from 'app/features/fileSlice'
import { UploadModal } from 'components/shared/Modal/UploadModal'

export const Contents = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { showMessageMirror, showMessageLoop, selectedFiles, files, qntyFiles } = useSelector(({ files }) => files)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchDocuments = async () => {
    try {
      const response = await dispatch(getDocuments())
      if (response.status === 200) {
        const documents = response.data.documents
        dispatch(setFile(documents))
      }
    } catch (error) {
      console.error('Error fetching documents:', error)
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [])

  useEffect(() => {
    if (qntyFiles !== 1) {
      dispatch(setShowMessageLoop(true))
    } else {
      dispatch(setShowMessageLoop(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qntyFiles])

  const handleClick = () => {
    setShowModal(true)
  }

  const handleFileUpload = (file, source, callback) => {
    const formData = new FormData()

    if (source === 'FILE_UPLOAD') {
      formData.append('file', file)
    } else if (source === 'EURLEX_URL') {
      formData.append('url', file)
    }
    formData.append('source', source)

    setIsLoading(true)
    dispatch(uploadDocument(formData))
      .then((response) => {
        dispatch(addFile(response.data))
        callback(response)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
        setShowModal(false)
      })
  }

  const handleFileSelection = (index) => {
    dispatch(toggleFileSelection(index))
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <img src="/icons/down.png" alt="logo" className="h-4 w-4 object-cover" />
          <p className="big">Contents</p>
        </div>

        <div className="flex justify-end">
          <button htmlFor="fileInput" onClick={handleClick} className="small cursor-pointer text-[14px] text-[#3163E4]">
            + Add
          </button>
        </div>
      </div>
      <div className="mb-8 mt-2 shrink grow basis-0 overflow-y-auto">
        <div className="flex flex-col pb-2">
          {files.map((val, index) => (
            <button
              key={index}
              className={`my-1 mr-2 flex  rounded p-2 text-sm ${
                selectedFiles.includes(index) ? 'bg-[#E6EDFF]' : 'bg-[#FBFBFB] hover:bg-[#E6EDFF]'
              }`}
              onClick={() => {
                handleFileSelection(index)
              }}
            >
              <div className="small overflow-auto text-[14px] text-[#383838] no-underline hover:no-underline">
                {val?.title}
              </div>
            </button>
          ))}
        </div>
      </div>

      {(showMessageLoop || showMessageMirror) && (
        <div className="h-20">
          {showMessageLoop && pathname === '/playground/loop' && (
            <div className="small rounded bg-yellow-200 px-4 py-2">Please select one file.</div>
          )}

          {showMessageMirror && pathname === '/playground/mirror' && (
            <div className="small rounded bg-yellow-200 px-4 py-2">
              Please upload two PDF files and select or enter at least one question.
            </div>
          )}
        </div>
      )}

      <UploadModal
        handleFileUpload={handleFileUpload}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isVisible={showModal}
      />
    </>
  )
}
