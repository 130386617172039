import { cleanSelectedFiles } from 'app/features/fileSlice'
import { resetLoop } from 'app/features/loopSlice'
import { resetmirrorState } from 'app/features/mirrorSlice'
import { resetsmartchatState } from 'app/features/smartChatSlice'
import { DefaultModal } from 'components/shared/Modal/DefaultModal'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const CleanDataModal = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleReset = () => {
    const { pathname } = location
    if (pathname.includes('/loop')) {
      dispatch(resetLoop())
    } else if (pathname.includes('/mirror')) {
      dispatch(resetmirrorState())
    } else if (pathname.includes('/assistant') || pathname === '/playground') {
      dispatch(resetsmartchatState())
    }
    dispatch(cleanSelectedFiles())
    setShowConfirmation(false)
  }

  return (
    <>
      <button
        onClick={() => setShowConfirmation(true)}
        className="m-2 flex items-center rounded border bg-white px-4 py-2 text-[#555555] hover:bg-blue-100"
      >
        <img className="mr-2 h-5 w-5" src="/icons/eraser.png" alt="logo" />
        <p className="big">Reset</p>
      </button>

      <DefaultModal
        title="Clear workspace"
        isVisible={showConfirmation}
        onSubmit={handleReset}
        onClose={() => setShowConfirmation(false)}
        size="s"
      >
        <p>This action will clear your workspace.</p>
        <p>Do you want to continue?</p>
      </DefaultModal>
    </>
  )
}
