import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Bookmark from './components/shared/Bookmark/Bookmark.jsx'
import Files from './components/shared/Files/Files.jsx'
import Layout from './components/shared/Layout'
import Login from './components/shared/Login/Login.jsx'
import Logs from './components/shared/Logs/Logs.jsx'
import Mirror from './components/shared/Mirror/MainNew.jsx'
import SmartChat from './components/shared/SmartChat/MainNew.jsx'
import Loop from './pages/playground/loop/Loop.jsx'
import ProtectedRoute from './protectedRoutes/protectedRoutes.jsx'

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute redirectAuthenticated={true} />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Navigate to="/playground/assistant" replace />} />
            <Route path="/playground" element={<Navigate to="/playground/assistant" replace />} />
            <Route path="/playground/assistant" element={<SmartChat />} />
            <Route path="/playground/loop" element={<Loop />} />
            <Route path="/playground/mirror" element={<Mirror />} />
            <Route path="/bookmarks" element={<Bookmark />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/files" element={<Files />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App
