import { Tooltip } from 'react-tooltip'
import { useRandomID } from '../../../hooks/useRandomId'

export const ReactTooltip = ({
  tooltipContent,
  ariaLabel,
  children,
  enabled = true,
  place = 'bottom',
  opacity = 0.9,
  positionStrategy = 'absolute',
  ...rest
}) => {
  const { generateRandomID } = useRandomID()
  const id = generateRandomID()

  return enabled ? (
    <div aria-label={ariaLabel}>
      <div id={id}>{children}</div>
      <Tooltip
        clickable
        anchorSelect={`#${id}`}
        opacity={opacity}
        place={place}
        positionStrategy={positionStrategy}
        className="max-w-52"
        {...rest}
      >
        {tooltipContent}
      </Tooltip>
    </div>
  ) : (
    <>{children}</>
  )
}
