import React from 'react'

const BookMarkDoc = ({ name }) => {
  return (
    <div className="flex items-center justify-between mt-3 mr-5 small w-full py-3 px-4 rounded border border-gray-300">
      <span className="left-text">{name}</span>
      <span className="right-text text-[#3244E8] cursor-pointer">Download</span>
    </div>
  )
}

export default BookMarkDoc
