import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { twJoin } from 'tailwind-merge'
import { ModalFooter } from './components/ModalFooter'
import { ModalHeader } from './components/ModalHeader'

const SIZES = {
  s: 'w-96',
  m: 'w-[638px]',
  l: 'w-[980px]'
}

export const DefaultModal = ({
  isVisible,
  onSubmit,
  onClose,
  children,
  title,
  isLoading,
  isDisabled,
  className,
  size = 'm'
}) => {
  return (
    <>
      {isVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-400 bg-opacity-70 outline-none focus:outline-none">
          <ClickAwayListener onClickAway={() => !isLoading && onClose()}>
            <div className={twJoin(SIZES[size])}>
              <div
                className={twJoin(
                  'flex w-full flex-col justify-between rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none',
                  className
                )}
              >
                <ModalHeader onClose={onClose} title={title} isLoading={isLoading} />
                <div className="p-4 text-sm">{children}</div>
                <ModalFooter onClose={onClose} onSubmit={onSubmit} isDisabled={isDisabled} isLoading={isLoading} />
              </div>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}
