import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'

export default function Layout() {
  return (
    <div className="flex h-screen w-screen overflow-hidden bg-neutral-100">
      <Sidebar />
      <div className="h-full w-full overflow-y-auto px-4 py-5">
        <Outlet />
      </div>
    </div>
  )
}
