import * as api from '../index.js'
export const postMirror = (text, file1, file2) => async (dispatch) => {
  try {
    const data = {
      name: file1.title + '-' + file2.title,
      document_a: file1.id,
      document_b: file2.id,
      ...(text ? { questions: text } : {})
    }

    const response = await api.postMirror(data)
    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
export const getMirrorList = () => async (dispatch) => {
  try {
    const response = await api.getMirrorList()
    if (response.status < 300) {
      return { status: response.status, data: response.data }
    }
  } catch (error) {
    if (error.response) {
      return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
export const addQuestion = (id, body) => async (dispatch) => {
  try {
    const data = {
      data: body
    }
    const response = await api.addQuestion(id, data)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
export const getSingleMirror = (id) => async (dispatch) => {
  try {
    const response = await api.getSingleMirror(id)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const downloadPDF = (extractorid) => async (dispatch) => {
  try {
    const response = await api.downloadPDF(extractorid, { responseType: 'blob' });

    if (response.status < 300) {
      return { status: response.status, blob: response.data };
    } else {
      return { status: response.status, error: "Failed to download PDF" };
    }
  } catch (error) {
    if (error.response) {
      return { error: error.response.data.message ?? error.message, status: error.response.status ?? 400 };
    } else {
      const e = error.toJSON ? error.toJSON() : { message: error.message, error_code: 400 };
      return { error: e.message, status: e.error_code };
    }
  }
}
