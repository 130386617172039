import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveButton } from '../../../app/features/navigationSlice'
import TutorialModal from './TutorialModel'
import CleanData from './cleandata'

const SubHeader = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { pdfStatus, loadingShow } = useSelector((state) => state.knowledge)

  const handleButtonClick = (buttonName) => {
    dispatch(setActiveButton(buttonName))
  }

  return (
    <div className="w-full flex flex-col">
      <div className="flex border-b py-2  justify-between">
        {/* Buttons aligned to the left */}
        <div className="flex mx-2 justify-center items-center">
          <Link
            className={`no-underline flex  hover:no-underline text-black m-1 mt-1 hover:bg-blue-100 px-3 py-2  rounded ${
              pathname === '/playground'
                ? 'bg-blue-100 rounded'
                : `${pathname === '/playground/assistant' ? 'bg-blue-100 rounded' : 'bg-gray-100'}`
            } `}
            onClick={() => {
              handleButtonClick('SmartChat')
            }}
            to="/playground/assistant"
          >
            <p className="big">Assistant</p>
          </Link>
          <Link
            className={`no-underline flex hover:no-underline   text-black  m-1 mt-1 hover:bg-blue-100 px-3 py-2  rounded ${
              pathname === '/playground/loop' ? ' bg-blue-100 rounded' : 'bg-gray-100'
            }`}
            onClick={() => {
              handleButtonClick('KnowledgeExtracter')
            }}
            to="/playground/loop"
          >
            <p className="big">Loop</p>
          </Link>

          <Link
            className={`no-underline hover:no-underline active:bg-blue-100  text-black  m-1 mt-1 hover:bg-blue-100  px-3 py-2 big rounded ${
              pathname === '/playground/mirror' ? ' bg-blue-100 rounded' : 'bg-gray-100'
            }`}
            onClick={() => {
              handleButtonClick('Mirror')
            }}
            to="/playground/mirror"
          >
            Mirror
          </Link>
        </div>

        {/* Buttons aligned to the right */}
        <div className="flex ">
          <CleanData />
          <TutorialModal />
        </div>
      </div>
      <div className="h-[10px] w0full">
        {pdfStatus === 'generating' && pathname === '/playground/loop' && (
          <div className="demo-container">
            <div className="progress-bar">
              <div className="progress-bar-value"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubHeader
