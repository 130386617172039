import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { refresh } from './Login/index'

export const baseURL = process.env.REACT_APP_API_URL
export const API = axios.create({ baseURL: baseURL })

API.interceptors.request.use(
  async (req) => {
    let accessToken = JSON.parse(localStorage.getItem('access_token'))
    const refreshToken = JSON.parse(localStorage.getItem('refresh_token'))

    if (!accessToken && refreshToken) {
      try {
        const response = await refresh({ refresh_token: refreshToken })
        if (response.status < 202) {
          accessToken = response.data.access_token
          localStorage.setItem('access_token', JSON.stringify(accessToken))
        } else {
          throw new Error('Failed to refresh access token')
        }
      } catch (error) {
        console.error('Error refreshing token:', error)
      }
    }
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken)
      const now = Date.now() / 1000

      if (decodedToken.exp < now + 20) {
        localStorage.removeItem('access_token')
        const refreshToken = JSON.parse(localStorage.getItem('refresh_token'))
        try {
          const response = await refresh({ refresh_token: refreshToken })

          if (response.status < 300) {
            localStorage.setItem('access_token', JSON.stringify(response.data.access_token))
            req.headers.Authorization = `Bearer ${response.data.access_token}`
          }
        } catch (error) {
          console.error('Error refreshing token:', error)
        }
      } else {
        req.headers.Authorization = `Bearer ${accessToken}`
      }
    }
    return req
  },
  (error) => {
    return Promise.reject(error)
  }
)
