import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from '../../../../styles/Markdown.module.css'
import { useCopyText } from '../../../../hooks/useCopyText'

export const Answer = ({ content, toggleExpand, isExpanded = true }) => {
  const { copyToClipboard } = useCopyText()

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <h1 className="text-[14px] text-[#5F5978] text-pretty font-semibold">{content.data}</h1>
        </div>
        <div className="flex items-center gap-1">
          <button className="m-1" onClick={() => copyToClipboard(content.response)}>
            <img className="w-5 h-5" src="/icons/copy.svg" alt="copy" />
          </button>
          <button className="m-1" onClick={() => toggleExpand(content.id, !isExpanded)}>
            <img
              key={content.id}
              className="h-4"
              src={`${isExpanded ? '/icons/down.png' : '/icons/up.png'}`}
              alt="toggle"
            />
          </button>
        </div>
      </div>
      {isExpanded && (
        <div key={content.id} className="small">
          <div className="pb-2 text-[13px]">
            <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
              {content.response}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </>
  )
}
