import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pdfStatus: '',
  prompt: '',
  loadingShow: null,
  chatshow: false,
  data: null,
  extractorIdRedux: null
}

const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    setPdfStatus: (state, action) => {
      state.pdfStatus = action.payload
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload
    },
    setLoadingShow: (state, action) => {
      state.loadingShow = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setExtractorIdRedux: (state, action) => {
      state.extractorIdRedux = action.payload
    },
    setChatshow: (state, action) => {
      state.chatshow = action.payload
    },
    resetknowledgeState: () => initialState
  }
})

export const {
  setPdfStatus,
  setPrompt,
  setLoadingShow,
  setChatshow,
  resetknowledgeState,
  setData,
  setExtractorIdRedux
} = knowledgeSlice.actions
export default knowledgeSlice.reducer
