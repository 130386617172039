import { API } from '../baseUrl'
export const createThread = async (body) => await API.post(`/assistant/thread/`, body)
export const getSingleThread = async (threadId) => await API.get(`/assistant/thread/${threadId}/`)
export const getThreadMessages = async (threadId) => await API.get(`/assistant/thread/${threadId}/message/list`)
export const downloadThreadPDF = async (threadId) => {
  return await API.get(`/assistant/thread/${threadId}/download_pdf`, {
    responseType: 'blob'
  });
}
export const askQuestion = async (threadId, body) => await API.post(`/assistant/thread/${threadId}/message/`, body)
export const addThreadDocument = async (threadId, documentId) =>
  await API.post(`/assistant/thread/${threadId}/document/${documentId}/`)
export const removeThreadDocument = async (threadId, documentId) =>
  await API.delete(`/assistant/thread/${threadId}/document/${documentId}/`)
