import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  presets: [
    { input: 'Default Prompt 1', textField: 'Default Description 1', selected: false },
    { input: 'Default Prompt 2', textField: 'Default Description 2', selected: false }
  ]
}

const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState,
  reducers: {
    addPreset(state, action) {
      // state.presets.push(action.payload);
      state.presets = action.payload
    },
    deletePreset(state, action) {
      state.presets.splice(action.payload, 1)
    },
    togglePresetSelection(state, action) {
      const index = action.payload
      // Deselect all presets
      state.presets.forEach((preset, idx) => {
        if (idx !== index) {
          preset.selected = false
        }
      })
      // Toggle the selected preset
      state.presets[index].selected = !state.presets[index].selected
    },
    editPresetText(state, action) {
      const { index, text } = action.payload
      state.presets[index].textField = text
    }
  }
})

export const { addPreset, deletePreset, togglePresetSelection, editPresetText } = knowledgeSlice.actions
export default knowledgeSlice.reducer
