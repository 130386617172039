import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import { toast } from 'react-toastify'
import { toggleFileSelection, setFile, addFile } from '../../../app/features/fileSlice' // Update import
import { getDocuments, uploadDocument } from '../../../api/Document/action/document'
import { ReactTooltip } from '../Tooltip/ReactTooltip'

function Files({ children }) {
  const { pathname } = useLocation()
  const showMessageMirror = useSelector((state) => state.files.showMessageMirror)
  const showMessageKnowledge = useSelector((state) => state.files.showMessageKnowledge)
  const selectedFilesRedux = useSelector((state) => state.files.selectedFiles)
  const files = useSelector((state) => state.files.files)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleClick = () => {
    setShowModal(true)
  }

  const fetchDocuments = async () => {
    try {
      const response = await dispatch(getDocuments())
      if (response.status === 200) {
        const documents = response.data.documents
        dispatch(setFile(documents))
      }
    } catch (error) {
      console.error('Error fetching documents:', error)
    }
  }

  const handleFileUpload = (file, source, callback) => {
    const formData = new FormData()

    if (source === 'FILE_UPLOAD') {
      formData.append('file', file)
    } else if (source === 'EURLEX_URL') {
      formData.append('url', file)
    }
    formData.append('source', source)

    setIsLoading(true)
    dispatch(uploadDocument(formData))
      .then((response) => {
        dispatch(addFile(response.data))
        callback(response)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
        setShowModal(false)
      })
  }

  const handleFileSelection = (index) => {
    dispatch(toggleFileSelection(index))
  }

  useEffect(() => {
    fetchDocuments()
  }, [])

  return (
    <>
      <div>
        <div className="flex mb-2 justify-between">
          <div className="flex items-center gap-1">
            <img src="/icons/down.png" alt="logo" className="w-4 h-4 object-cover" />
            <p className="big">Contents</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ReactTooltip tooltipContent="Click here to add new content" positionStrategy="fixed" place="top">
              <button
                htmlFor="fileInput"
                onClick={handleClick}
                className="text-[#3163E4] text-[14px] small cursor-pointer"
              >
                + Add
              </button>
            </ReactTooltip>
          </div>
        </div>
        {showModal && (
          <UploadPlus handleFileUpload={handleFileUpload} setShowModal={setShowModal} isLoading={isLoading} />
        )}
        <div className="flex max-h-40 h-40 overflow-y-auto flex-col py-2">
          {files.map((val, index) => (
            <button
              key={index}
              className={`my-1 mr-2 flex  p-2 text-sm rounded ${
                selectedFilesRedux.includes(index) ? 'bg-[#E6EDFF]' : 'bg-[#FBFBFB] hover:bg-[#E6EDFF]'
              }`}
              onClick={() => {
                handleFileSelection(index)
              }}
            >
              <button className="text-[14px] small no-underline hover:no-underline text-[#383838] overflow-auto">
                {val?.title}
              </button>
            </button>
          ))}
        </div>
      </div>
      <div className="h-20 ">
        {showMessageKnowledge && pathname === '/playground/loop' && (
          <div className="bg-yellow-200 small py-2 px-4 rounded">Please select only one content.</div>
        )}

        {showMessageMirror && pathname === '/playground/mirror' && (
          <div className="bg-yellow-200 small py-2 px-4 rounded">
            Please upload two PDF files and select or enter at least one question.
          </div>
        )}
      </div>
      {children}
    </>
  )
}

export default Files

function UploadPlus({ handleFileUpload, setShowModal, isLoading }) {
  const [dragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploadedFileName, setUploadedFileName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  // const [addedMessage, setAddedMessage] = useState('')
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [loadingSpiral, setLoadingSpiral] = useState(true)
  const [sourceType, setSourceType] = useState('FILE_UPLOAD')

  const handleSourceTypeChange = (event) => {
    setSourceType(event.target.value)
    setUploadedFileName('')
    setFile('')
    setFileName('')
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    setFileName('')
    setErrorMessage('')
    const files = e.dataTransfer.files
    if (files && files.length > 0) {
      setUploadedFileName(files[0].name)

      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        if (!files[0].name) {
          setErrorMessage('Incompatible document!')
        } else {
          setFileName(files[0].name)
        }
      }, 3000)
    }
  }

  const handleFileUploadWithLoading = (event) => {
    setErrorMessage('')
    setFileName('')
    setLoading(true)
    setTimeout(() => {
      if (sourceType === 'FILE_UPLOAD') {
        setLoading(false)
        setFile(event.target.files[0])
        setUploadedFileName(event.target.files[0].name)
        setFileName(event.target.files[0].name)
      } else if (sourceType === 'EURLEX_URL') {
        setLoading(false)
        setFile(event.target.value)
        setUploadedFileName(event.target.value)
        setFileName(event.target.value)
      }
    }, 1000)
  }
  const handleProcess = () => {
    setErrorMessage('')
    setFileName('')
    if (!uploadedFileName) {
      // logic for the incompitable file
      // if (!uploadedFileName) {
      //     setLoading(true)
      //     setFileName('')
      //     setLoadingSpiral(false)
      //     setTimeout(() => {
      //         setLoading(false)
      //         setLoadingSpiral(true)
      //         setErrorMessage('Incompatible document!')
      //     }, 3000)
    } else {
      setLoadingSpiral(false)
      setLoading(true)
      setFileName('')
      setTimeout(() => {
        setLoadingSpiral(true)
        setFileName('')
        setLoading(false)
        handleFileUpload(sourceType === 'FILE_UPLOAD' ? file : uploadedFileName, sourceType, (response) => {
          if (response.status === 201 && !response.error && response.data.id) {
            setLoading(false)
            toast.success('File processed successfully')
          } else {
            toast.error('Error processing file. Please try again')
            setErrorMessage('Error')
            setLoading(false)
          }
        })
        setUploadedFileName('') // Reset uploadedFileName after processing
      }, 3000)
    }
  }

  const isProcessDisabled =
    (sourceType === 'FILE_UPLOAD' && !file && !uploadedFileName) || (sourceType === 'EURLEX_URL' && !uploadedFileName)

  return (
    <>
      <div
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${
          dragging ? 'bg-blue-400 bg-opacity-25' : ''
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <div className="mx-auto w-[40rem]">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
              <h5 className="text-gray-500 big mt-2">Content Upload</h5>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>

            <>
              {(loading || isLoading) && (
                <div className="demo-container">
                  <div className="progress-bar">
                    <div className="progress-bar-value"></div>
                  </div>
                </div>
              )}
            </>
            {loadingSpiral && !isLoading ? (
              <>
                <>
                  {errorMessage !== '' && (
                    <div
                      className={` small p-2 ${
                        errorMessage === 'Incompatible document!' ? 'text-red-500' : 'text-green-500'
                      } `}
                    >
                      {errorMessage}
                    </div>
                  )}
                </>

                <div
                  className={twJoin(
                    'flex mx-4 my-4 justify-between gap-6',
                    sourceType === 'FILE_UPLOAD' ? 'items-center' : 'items-end'
                  )}
                >
                  {sourceType === 'FILE_UPLOAD' ? (
                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center">
                      <div className="flex w-6/10 border-2 border-gray-300 bg-blue-50 rounded-lg items-center justify-center cursor-pointer">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col items-center justify-center h-36 cursor-pointer"
                        >
                          <div className="flex flex-col items-center justify-center">
                            <svg
                              className="w-8 h-8 mb-4 text-gray-500"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <div className="small text-center justify-center align-content-center w-[400px] text-gray-500 ">
                              {fileName !== '' ? (
                                <p className="wrapped-text">{fileName}</p>
                              ) : (
                                <p>
                                  <span className="font-semibold">Click to upload</span> or drag and drop{' '}
                                </p>
                              )}
                            </div>
                          </div>
                          <input
                            onChange={(e) => handleFileUploadWithLoading(e)}
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                          />
                        </label>
                      </div>
                    </label>
                  ) : (
                    <input
                      type="text"
                      placeholder="Enter URL to start the process"
                      className="h-7 w-full flex-1 indent-2 text-xs border border-gray-300 rounded-md"
                      onChange={(e) => {
                        setUploadedFileName(e.target.value)
                      }}
                    />
                  )}
                  <div className="w-4/10 w-44">
                    <h1 className="big text-gray-600">Source Selection</h1>
                    <select
                      className="mt-2 text-gray-500 w-full border small border-gray-300 rounded-md p-1 cursor-pointer"
                      value={sourceType}
                      onChange={handleSourceTypeChange}
                    >
                      <option value="FILE_UPLOAD">File</option>
                      <option value="EURLEX_URL">URL</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="py-1 font-semibold px-5 m-2 border rounded big cursor-pointer hover:bg-blue-100"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className={twJoin(
                      'text-white bg-blue-600 py-1 font-semibold px-5 m-2  rounded big hover:bg-blue-500',
                      isProcessDisabled ? 'opacity-50 cursor-not-allowed hover:bg-blue-600' : 'cursor-pointer'
                    )}
                    type="button"
                    onClick={handleProcess}
                    disabled={isProcessDisabled}
                  >
                    Process
                  </button>
                </div>
              </>
            ) : (
              <div className="h-[235px] flex flex-col items-center justify-center">
                <h1 className="font-bold text-lg">Processing... </h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
