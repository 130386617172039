import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isGenerated: false,
  isLoading: false,
  stop: false,
  isListVisible: false,
  tasks: [],
  generatedResponses: [],
  pdfStatus: null, // Add pdfStatus to the initial state
  mirrorIdRedux: null // Add pdfStatus to the initial state
}

const mirrorSlice = createSlice({
  name: 'mirror',
  initialState,
  reducers: {
    setIsGenerated: (state, action) => {
      state.isGenerated = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setStop: (state, action) => {
      state.stop = action.payload
    },
    setIsListVisible: (state, action) => {
      state.isListVisible = action.payload
    },
    addGeneratedResponse: (state, action) => {
      const response = action.payload
      if (!state.generatedResponses.includes(response)) {
        state.generatedResponses.push(response)
      }
    },
    setTasks: (state, action) => {
      state.tasks = action.payload
    },
    setMirrorIdRedux: (state, action) => {
      state.mirrorIdRedux = action.payload
    },
    addTask: (state, action) => {
      state.tasks.push(action.payload)
    },
    removeTask: (state, action) => {
      state.tasks = state.tasks.filter((_, index) => index !== action.payload)
    },
    setPdfStatus: (state, action) => {
      state.pdfStatus = action.payload
    },
    resetmirrorState: () => initialState
  }
})

export const {
  setIsGenerated,
  setIsLoading,
  setStop,
  setIsListVisible,
  setTasks,
  addTask,
  removeTask,
  setPdfStatus, // Export the setPdfStatus action
  resetmirrorState,
  addGeneratedResponse,
  setMirrorIdRedux
} = mirrorSlice.actions

export default mirrorSlice.reducer
