import { addTask } from 'api/Task/action/action'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { DefaultModal } from './DefaultModal'

export const AddTaskModal = ({ onSave, onCancel, workspace, showModal }) => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')
  const [textFieldValue, setTextFieldValue] = useState('')

  const handleAddPreset = async () => {
    if (!inputValue.trim()) {
      toast.error('Please fill title field')
      return
    }
    if (!textFieldValue.trim()) {
      toast.error('Please fill instruction field')
      return
    }
    const response = await dispatch(addTask(inputValue, textFieldValue, workspace))

    if (!response.status || response.status > 201) {
      toast.error(response.data)
      return
    }
    toast.success('Task Added Successfully')
    onSave()
    setInputValue('')
    setTextFieldValue('')
  }

  const handleAddPresetCancel = () => {
    onCancel()
    setInputValue('')
    setTextFieldValue('')
  }

  return (
    <DefaultModal title="Add task" isVisible={showModal} onSubmit={handleAddPreset} onClose={handleAddPresetCancel}>
      <div className="flex flex-col gap-2">
        <div>
          <label className="big ml-2">Title</label>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter Title"
            className="small m-2 w-full rounded border px-4 py-2"
          />
        </div>
        <div>
          <label className="big ml-2">Instruction</label>
          <textarea
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
            placeholder="Enter Instruction"
            className="small m-2 max-h-40 w-full rounded border px-4 py-2"
            rows="4"
          />
        </div>
      </div>
    </DefaultModal>
  )
}
