import { DefaultModal } from 'components/shared/Modal/DefaultModal'

export const MirrorTutorialModal = ({ setShowModal, showModal }) => {
  return (
    <DefaultModal title="Mirror Tutorial" isVisible={showModal} onClose={() => setShowModal(false)}>
      <h1 className="text-[22px] font-semibold">Here's a quick guide:</h1>
      <p className="my-2 mt-4 text-[14px] leading-relaxed text-[#575757]">
        1. 📁 File Upload : Use the File upload section to select and upload two PDF files for comparison.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        2. ⚙️ Choose Questions: In the "Configuration" section, select predefined questions relevant to your comparison
        or enter custom questions.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        3. 🔄 Start Comparison: Click "Start the Comparation" to process your files and questions.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        4. 🤖 Review Results: View the AI's responses to your questions, detailing comparisons, key points, structural
        changes, and contradictions.
      </p>
    </DefaultModal>
  )
}
