import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twJoin, twMerge } from 'tailwind-merge'
import { DASHBOARD_SIDEBAR_LINKS } from 'lib/constants'

const linkClass = 'flex items-center gap-2 font-light hover:text-blue-700 hover:no-underline rounded-lg'
const linkClassDrop = 'flex items-center gap-1 font-light py-1 text-gray-400  hover:no-underline'

export default function Sidebar() {
  const [collapse, setCollapse] = useState(true)
  return (
    <div
      onMouseEnter={() => setCollapse(false)}
      onMouseLeave={() => setCollapse(true)}
      className={twMerge(
        'flex shrink-0 flex-col bg-white px-2 py-4',
        'w-14 transition-all duration-300 ease-in-out hover:w-60'
      )}
    >
      <Link to="/">
        <div className="m-1 flex items-center gap-2 px-1 py-1">
          <img className="mr-1 h-12 shrink-0" src="/icons/logo.png" alt="silmaril" />
          {!collapse && (
            <div className="mb-2 mt-auto">
              <img className="h-5" src="/icons/silmaril.png" alt="silmaril" />
            </div>
          )}
        </div>
      </Link>
      <div className="flex flex-1 flex-col gap-1 py-4">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} droplist={link.dropable} collapse={collapse} />
        ))}
      </div>
    </div>
  )
}
function SidebarLink({ link, collapse }) {
  const { pathname } = useLocation()
  const [drop, setDrop] = useState(link.drop)
  const isActive = pathname.startsWith(link.path)

  const handleDropdownClick = (e) => {
    e.stopPropagation() // Stop the event from bubbling up to higher components
    e.preventDefault() // Prevent default link behavior
    setDrop(!drop)
  }

  return (
    <>
      <Link
        to={link.disabled ? '#' : link.path}
        className={twMerge(
          isActive && !link.disabled ? 'rounded-lg bg-blue-100 text-blue-800 active:bg-blue-100' : 'text-black',
          linkClass,
          link.disabled ? 'bg-hover:transparent cursor-not-allowed' : 'hover:bg-blue-100',
          'py-2'
        )}
      >
        <div className="flex shrink-0 items-center">
          <span className="mx-2 text-xs text-gray-600">
            <img
              className={twJoin('h-6 w-6', link.disabled ? 'cursor-not-allowed opacity-50' : '')}
              src={link.icon}
              alt={`${link.label} logo`}
            />
          </span>

          {!collapse && (
            <span
              className={twJoin('text-[14px] text-[#1C1C1C]', link.disabled ? 'cursor-not-allowed opacity-50' : '')}
            >
              {link.label}
            </span>
          )}
        </div>

        {link.drop && (
          <div>
            {drop ? (
              <img
                onClick={handleDropdownClick}
                className="h-4 w-4 text-sm hover:cursor-pointer"
                src="/icons/up.png"
                alt="down arrow"
              />
            ) : (
              <img
                onClick={handleDropdownClick}
                className="h-4 w-4 text-sm hover:cursor-pointer"
                src="/icons/down.png"
                alt="up arrow"
              />
            )}
          </div>
        )}
      </Link>
      {drop && link.dropable && (
        <div className="pl-3">
          {link.dropable.map((sublink) => (
            <SidebarLinkDrop key={sublink.key} link={sublink} />
          ))}
        </div>
      )}
    </>
  )
}

function SidebarLinkDrop({ link }) {
  return (
    <Link to={link.path} className={linkClassDrop}>
      <span className="px-3 text-xs text-gray-400">
        <img className="h-4 w-4" src={link.icon} alt={`${link.label} logo`} />
      </span>
      <p className="text-sm text-gray-400">{link.label}</p>
    </Link>
  )
}
