import React, { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import remarkGfm from 'remark-gfm'
import {
  addThreadDocument,
  askQuestion,
  createThread,
  downloadThreadPDF,
  removeThreadDocument
} from '../../../api/SmartAssistant/action/action'
import { addData, setPdfStatus, setThreadIdRedux } from '../../../app/features/smartChatSlice'
import styles from '../../../styles/Markdown.module.css'

import { useCopyText } from '../../../hooks/useCopyText.jsx'
import { ASSISTANT } from '../../../lib/constants/BookMarkConst'
import { DefaultButton } from '../Buttons/DefaultButton.jsx'
import { TextareaWithButton } from '../Inputs/TextareaWithButton.jsx'
import { DarkSpinner } from '../Loaders/DarkSpinner.jsx'
import { Spinner } from '../Loaders/Spinner.jsx'
import { DefaultModal } from '../Modal/DefaultModal.jsx'
import { ReactTooltip } from '../Tooltip/ReactTooltip.jsx'
import AddBookmarkModal from '../Usable/AddBookMarkModel'

const SmartChat = () => {
  const dispatch = useDispatch()
  const { pdfStatus, data, threadIdRedux } = useSelector((state) => state.smartChat)
  const [isFirstPost, setIsFirstPost] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isPopup, setIsPopup] = useState(false)
  const [threadId, setThreadId] = useState(null)
  const [message, setMessage] = useState('')
  const { selectedFiles, files } = useSelector((state) => state.files)
  const [selectedFilesCopy, setSelectedFilesCopy] = useState([])

  const [showAddBookMarkModal, setShowAddBookMarkModal] = useState(false)
  const [activeButtonLoc, setActiveButton] = useState(null)
  const [syncWithRedux, setSyncWithRedux] = useState(true)

  const containerRef = useRef(null)
  const { copyToClipboard } = useCopyText()
  const isDisabledGeneratePDF = pdfStatus === 'generating' || data.length === 0

  useEffect(() => {
    if (containerRef.current && isLoading) {
      scrollToBottom()
    }
  }, [containerRef, isLoading])

  useEffect(() => {
    scrollToBottom()
  }, [data, pdfStatus])

  const handleClick = async (buttonId) => {
    setActiveButton((prevActiveButton) => (prevActiveButton === buttonId ? null : buttonId))

    if (buttonId === 'generatePdf') {
      dispatch(setPdfStatus('generating'))

      try {
        const response = await dispatch(downloadThreadPDF(threadIdRedux))

        if (response.blob) {
          dispatch(setPdfStatus('downloaded'))

          const downloadUrl = window.URL.createObjectURL(response.blob)
          const link = document.createElement('a')
          link.href = downloadUrl
          const date = new Date()
          const formattedDate = `${date.getDate()}_${
            date.getMonth() + 1
          }_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}`
          link.download = `Assistant_${formattedDate}.pdf`
          link.click()

          window.URL.revokeObjectURL(downloadUrl)
        } else {
          console.error('Failed to download PDF:', response.error || 'Unknown error')
          dispatch(setPdfStatus('error'))
        }
      } catch (error) {
        console.error('Error downloading the PDF:', error)
        dispatch(setPdfStatus('error'))
      }
    }
  }

  useEffect(() => {
    if (threadId) {
      const filesAdded = selectedFiles.filter((index) => !selectedFilesCopy.includes(index))
      const filesRemoved = selectedFilesCopy.filter((index) => !selectedFiles.includes(index))
      if (filesAdded.length > 0) {
        console.log('filesAdded', filesAdded)
        filesAdded.forEach((index) =>
          dispatch(addThreadDocument(threadId, files[index].id)).then((response) => {
            if (!response.status || response.status > 300) {
              toast.error(response.data)
              return
            }
            setSelectedFilesCopy(selectedFiles)
          })
        )
      } else if (filesRemoved.length > 0) {
        console.log('filesRemoved', filesRemoved)
        filesRemoved.forEach((index) => {
          dispatch(removeThreadDocument(threadId, files[index].id)).then((response) => {
            if (!response.status || response.status > 300) {
              toast.error(response.data)
              return
            }
            setSelectedFilesCopy(selectedFiles)
          })
        })
      }
    }
  }, [selectedFiles])

  const postData = async () => {
    var localThreadId = threadId

    const selectedFilesToUpload = selectedFiles.map((index) => files[index])
    if (isFirstPost) {
      const documentIds = selectedFilesToUpload.map((file) => file.id)
      const response = await dispatch(createThread(documentIds))

      if (!response.status || response.status > 201) {
        toast.error(response.data)
        return
      }
      setThreadId(response.data.id)
      dispatch(setThreadIdRedux(response.data.id))
      localThreadId = response.data.id
      setIsFirstPost(false)
    }
    if (localThreadId == null) return
    const newResponse = await dispatch(
      askQuestion(
        localThreadId,
        message,
        selectedFilesToUpload.map((file) => file.id)
      )
    )
    setMessage('')
    if (!newResponse.status || newResponse.status > 201) {
      toast.error(newResponse.data)
      return
    }
    setMessage('')
    dispatch(addData({ question: message, answer: newResponse.data.response }))
  }

  const confirmPopup = async (data) => {
    if (data) {
      setIsLoading(true)
      setIsPopup(false)

      await postData()
    }
    setIsPopup(false)
    setIsLoading(false)
  }

  const handleControl = async () => {
    dispatch(setPdfStatus('ready'))
    if (selectedFiles.length < 1) {
      setIsPopup(true)
      return
    }
    setIsLoading(true)
    await postData()
    setIsLoading(false)
  }

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  const { presets } = useSelector((state) => state.smartChattask)

  useEffect(() => {
    const selectedPreset = presets.find((preset) => preset.selected)
    if (selectedPreset) {
      setMessage(selectedPreset.data)
    } else {
      setMessage('')
    }
  }, [presets])

  const textAreaRef = useRef(null)

  useEffect(() => {
    const textArea = textAreaRef.current
    if (textArea) {
      textArea.style.height = 'auto'
      textArea.style.height = `${textArea.scrollHeight}px`
    }
  }, [message])
  const handleAddBookMark = (response) => {
    if (response.status <= 201) {
      toast.success('bookmark added successfully')
      setShowAddBookMarkModal(false)
      return
    }
    toast.error(response.data)
  }
  const handleAddBookMarkCancel = () => {
    setShowAddBookMarkModal(false)
  }

  const getIconButton = () =>
    pdfStatus === 'generating' ? (
      <div
        className="text-primary inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-blue-700 motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    ) : (
      <img className="h-5" src="/icons/download.png" alt="generate-pdf" />
    )

  return (
    <>
      <DefaultModal
        isVisible={isPopup}
        onClose={() => setIsPopup(false)}
        onSubmit={() => confirmPopup(true)}
        title="Generate answer"
        size="s"
      >
        <span>No file selected. Do you want to continue?</span>
      </DefaultModal>
      {showAddBookMarkModal && (
        <AddBookmarkModal
          onSave={handleAddBookMark}
          onCancel={handleAddBookMarkCancel}
          workspace_type={ASSISTANT}
          workspace_id={threadIdRedux}
        />
      )}
      <div style={{ height: '54.5vh' }} className=" flex h-full w-full flex-col justify-center ">
        <div
          style={{ height: '52.5vh' }}
          className="shadow-md-inner h-full items-center overflow-y-auto"
          ref={containerRef}
        >
          <div className="big flex grow-0 flex-col">
            {data.map((item, index) => (
              <div key={index}>
                <div className="mb-4 overflow-auto ">
                  <div className="mx-3 my-2 overflow-auto rounded-[0.75rem] bg-[#FBFBFB] px-2">
                    <div className="my-2 flex">
                      <div className="flex gap-1">
                        <div className="items-start justify-start align-top">
                          <button className="mx-2 w-10 rounded-lg  border bg-[#EAEAEA] p-2">
                            <img className="w-5" src="/icons/user.png" alt="logo" />
                          </button>
                        </div>
                        <p className="self-center text-pretty pr-2 leading-[1rem] text-[#575757]">{item.question}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 w-full overflow-y-auto">
                  <div className="mx-3 overflow-auto rounded-[0.75rem] bg-[#F6F4FF] px-2">
                    <div className="my-2 flex flex-col justify-between">
                      <div className="flex gap-1">
                        <div className="items-start justify-start align-top">
                          <button className="mx-2 w-10 rounded-lg border bg-[#DFD7FE] p-2">
                            <img className="w-5" src="/icons/ai.png" alt="logo" />
                          </button>
                        </div>
                        <p className="self-center text-pretty leading-[1rem] text-[#5F5978]">
                          <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
                            {item.answer}
                          </ReactMarkdown>
                        </p>
                      </div>
                      <div className="flex justify-end">
                        <button onClick={() => copyToClipboard(item.answer)}>
                          <img className="h-5 w-5" src="/icons/chatcopy.png" alt="Copy to clipboard" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isLoading && data.length === 0 && <DarkSpinner />}
          {isLoading && data.length !== 0 && (
            <div className="m-auto h-7 w-10 pb-4">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      <div className="mt-14">
        <div className=" flex items-center justify-center">
          <div className="flex gap-2 p-4">
            <ReactTooltip
              enabled={data.length === 0}
              tooltipContent="First, you need to generate some content"
              place="top"
            >
              <DefaultButton onClick={() => handleClick('generatePdf')} disabled={isDisabledGeneratePDF}>
                <div className="flex items-center justify-center gap-2">
                  {getIconButton()}
                  <span className={`big ${pdfStatus === 'generating' ? ' text-blue-700' : 'text-[#555555]'}`}>
                    {pdfStatus === 'generating' ? 'Generating PDF' : 'Generate PDF'}
                  </span>
                </div>
              </DefaultButton>
            </ReactTooltip>

            <DefaultButton
              onClick={() => setShowAddBookMarkModal(!showAddBookMarkModal)}
              disabled={pdfStatus === 'generating'}
              label="Bookmark"
              icon="/icons/bookmark.svg"
            >
              <div className="flex items-center justify-center gap-2">
                <img className="h-5" src="/icons/bookmark.svg" alt="logo" />
                <p className="big text-[#555555]">Bookmark</p>
              </div>
            </DefaultButton>
          </div>
        </div>

        <div className="mx-1 flex flex-col items-center px-3 py-2">
          <TextareaWithButton
            ref={textAreaRef}
            msg={message}
            isLoading={isLoading}
            handleOnChange={setMessage}
            handleOnClick={() => {
              handleControl()
              setSyncWithRedux(!syncWithRedux)
              scrollToBottom()
            }}
          />
        </div>
      </div>
    </>
  )
}

export default SmartChat
