import { twMerge } from 'tailwind-merge'

export const DefaultButton = ({ onClick, children, className, disabled = false }) => {
  return (
    <button
      className={twMerge(
        'rounded-md border px-2 py-2 hover:bg-blue-100',
        disabled && 'cursor-not-allowed opacity-60 hover:bg-transparent',
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
