import { useState } from 'react'
import Files from '../Usable/Files'
import Preset from '../Usable/preset'
import SubHeader from '../Usable/SubHeader'

export const Card = ({ utilities, title }) => {
  const [activeButton, setActiveButton] = useState(null)

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  }

  return (
    <>
      <div className="text-xl flex">
        <span>
          <img className="h-6" src="/icons/playground.png" alt="playground logo" />
        </span>
        <h1 className="ml-2 font-bold">{title}</h1>
      </div>

      <div className="bg-white mt-3 rounded-md shadow-md ">
        <SubHeader activeButton={activeButton} handleButtonClick={handleButtonClick} />
        <div className="flex h-full">
          <div className=" border-r w-80 h-full relative mt-3 px-5">
            <Files>
              <Preset />
            </Files>
          </div>

          <div className="my-2 w-4/5 flex flex-col justify-between h-full">{utilities}</div>
        </div>
      </div>
    </>
  )
}
