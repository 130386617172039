import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  files: [],
  selectedFiles: [],
  qntyFiles: 0,
  showMessageMirror: false,
  showMessageKnowledge: false,
  showMessageLoop: false
}

const fileSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    toggleFileSelection: (state, action) => {
      const index = action.payload
      if (state.selectedFiles.includes(index)) {
        state.selectedFiles = state.selectedFiles.filter((item) => item !== index)
        state.qntyFiles -= 1 // Decrease quantity when deselecting
      } else {
        state.selectedFiles.push(index)
        state.qntyFiles += 1 // Increase quantity when selecting
      }
    },
    setShowMessageMirror: (state, action) => {
      state.showMessageMirror = action.payload
    },
    setShowMessageKnowledge: (state, action) => {
      state.showMessageKnowledge = action.payload
    },
    setShowMessageLoop: (state, action) => {
      state.showMessageLoop = action.payload
    },
    setFile: (state, action) => {
      state.files = action.payload
    },
    addFile: (state, action) => {
      const fileName = action.payload
      if (!state.files.includes(fileName)) {
        state.files.push(fileName)
      }
    },
    cleanSelectedFiles: (state) => {
      state.selectedFiles = []
      state.qntyFiles = 0
    }
  }
})

export const {
  toggleFileSelection,
  setShowMessageMirror,
  setShowMessageKnowledge,
  setShowMessageLoop,
  addFile,
  setFile,
  cleanSelectedFiles
} = fileSlice.actions
export default fileSlice.reducer
