import { Divider, tableCellClasses } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState, useEffect } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import SortableTh from '../Bookmark/SortableTh'
import { useDispatch, useSelector } from 'react-redux'
import { getLogs, getSingleLog } from '../../../api/Log/action/logs.js'
import { format, parseISO } from 'date-fns'
import moment from 'moment'
import 'moment-timezone'

import { ASSISTANT, LOOP, MIRROR, WORKSPACE_TYPE } from '../../../lib/constants/BookMarkConst.jsx'
import { getSingleMirror } from '../../../api/Mirror/action/action.js'
import BookMarkDoc from '../Usable/BookMarkDoc.jsx'
import BookMarkOutput from '../Bookmark/MirrorBookMarkOutput.jsx'
import ExtractorBookMarkOutput from '../Bookmark/ExtractorBookMarkOutput.jsx'
import { getSingleExtractor } from '../../../api/Extractor/action/action.js'
import { getSingleThread, getThreadMessages } from '../../../api/SmartAssistant/action/action.js'
import { Header } from '../Headers/Header.jsx'

export default function Log() {
  const [details, setDetails] = useState([])

  const dispatch = useDispatch()

  const [logDetails, setLogDetails] = useState([])
  const [mirrorDetails, setMirrorDetails] = useState(null)
  const [extractorDetails, setExtractorDetails] = useState(null)
  const [threadDetails, setThreadDetails] = useState(null)
  const [threadMessages, setThreadMessages] = useState([])
  const [hasDocuments, setHasDocuments] = useState(true)
  useEffect(() => {
    const fetchLogs = async () => {
      const response = await dispatch(getLogs())
      if (response && Array.isArray(response.data.logs) && !response.error) {
        setDetails(response.data.logs)
      } else {
        setDetails([])
      }
    }

    fetchLogs()
  }, [])

  const [originalOpen, setOriginalOpen] = useState(false)
  const [aiOpen, setAiOpen] = useState(false)

  const [sidebarVisible, setSidebarVisible] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState(null)
  const [copyOn, setCopyOn] = useState(false)

  const getLogDetails = async (id) => {
    const response = await dispatch(getSingleLog(id))
    if (!response.status || response.status !== 200) return
    setLogDetails(response.data)
    if (response.data.workspace_type === MIRROR) {
      const mirrordetail = await dispatch(getSingleMirror(response.data.data.mirror))
      if (!response.status || response.status !== 200) return
      setMirrorDetails(mirrordetail.data)
    }
    if (response.data.workspace_type === LOOP) {
      const extractordetails = await dispatch(getSingleExtractor(response.data.data.extractor))
      if (!response.status || response.status !== 200) return
      setExtractorDetails(extractordetails.data)
    }
    if (response.data.workspace_type === ASSISTANT) {
      const threaddetails = await dispatch(getSingleThread(response.data.data.thread))
      if (!threaddetails.status || threaddetails.status !== 200) return

      if (threaddetails.data.documents.length === 0) {
        setHasDocuments(false)
      }
      setThreadDetails(threaddetails.data)

      const threadmessages = await dispatch(getThreadMessages(response.data.data.thread))
      if (!threadmessages.status || threadmessages.status !== 200) return

      setThreadMessages(threadmessages.data.messages)
    }
  }

  const openSidebar = async (index) => {
    setSelectedDetails(sortedDetails[index])
    await getLogDetails(sortedDetails[index].id)
    setSidebarVisible(true)
  }

  const closeSidebar = () => {
    setSidebarVisible(false)
    setSelectedDetails(null)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyOn(true)
        setTimeout(() => {
          setCopyOn(false)
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  const textForCopy =
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corruptium dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corruptium dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corruptium dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corruptium dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corruptium dolor sit amet consectetur adipisicing elit. Repellendus magni omnis sit est! Eos expedita omnis sapiente necessitatibus. Corrupti voluptatibus, vero, odit ratione dolorum porro vel ut possimus et obcaecati alias libero asperiores magni accusamus excepturi quaerat magnam harum ducimus consectetur? Officiis soluta magnam, hic velit repudiandae dignissimos vero minima, rem accusamus voluptatibus dolores magni fuga itaque perferendis, a unde impedit deserunt illum? Sed.'

  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' })

  const dayToIndex = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7
  }

  const sortedDetails = React.useMemo(() => {
    let sortableDetails = [...details]
    if (sortConfig.key) {
      sortableDetails.sort((a, b) => {
        if (sortConfig.key === 'updated_at') {
          const dateA = new Date(a[sortConfig.key])
          const dateB = new Date(b[sortConfig.key])

          if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1
          if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1
          return 0
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1
          }
          return 0
        }
      })
    }
    return sortableDetails
  }, [details, sortConfig])

  return (
    <div>
      <Header heading="Logs" icon="/icons/logdark.png" />
      <div className="w-full ">
        <div>
          <table className="w-full overflow-x-hidden rounded-xl border md:w-full lg:w-full">
            <thead className="bg-[#0000000D] text-left  text-xs text-[#00000066] ">
              <tr>
                <th scope="col" className="big">
                  S.NO
                </th>

                <SortableTh
                  title="Date/Time"
                  sortKey="updated_at"
                  // onSort={onSort}
                  sortConfig={sortConfig}
                  setSortConfig={setSortConfig}
                />
                <SortableTh
                  title="From"
                  sortKey="workspace_type"
                  // onSort={onSort}
                  sortConfig={sortConfig}
                  setSortConfig={setSortConfig}
                />
                <th scope="col" className="big pl-9 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedDetails.map((item, index) => (
                <tr key={index} className={`border-b bg-white  text-[#1C1C1C]  `}>
                  <th scope="row" className="small gap-1 whitespace-nowrap">
                    {index < 9 ? (
                      <>
                        <p>0{index + 1}</p>
                      </>
                    ) : (
                      <>
                        <p>{index + 1}</p>
                      </>
                    )}
                  </th>
                  {/* <th className="text-left gap-1">{item.Name}</th> */}
                  <td className="small gap-1">{moment(item.updated_at).tz(moment.tz.guess()).format('LLL')}</td>
                  <th className="small gap-1">{WORKSPACE_TYPE[item.workspace_type]}</th>
                  <td className="">
                    <div className="flex gap-2">
                      <button
                        onClick={() => openSidebar(index)}
                        className="small small rounded border pb-1 pl-4 pr-4 pt-1 text-blue-600 hover:bg-blue-100"
                      >
                        See Details
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {sidebarVisible && (
        <div className="fixed right-0 top-0 z-50 h-full w-1/3 overflow-auto bg-white shadow-lg">
          <div className="ml-3 mr-3 ">
            <div>
              {logDetails && (
                <div className="">
                  <div className="mt-4 flex justify-between">
                    <h1 className="small mb-2 font-semibold">{logDetails.name}</h1>
                    <button onClick={closeSidebar} className="mb-4 flex items-center  text-[14px] text-gray-500 ">
                      <img src={`${process.env.PUBLIC_URL}/icons/x.png`} className="small mr-1 h-3" alt="" />
                      Close
                    </button>
                  </div>
                  <div className="small">
                    <p className="small mb-2 ">
                      From: <span className="font-semibold "> {WORKSPACE_TYPE[logDetails.workspace_type]}</span>
                    </p>

                    <p className="small mb-5">
                      Date/Time:{' '}
                      <span className="font-semibold">
                        {moment(selectedDetails.updated_at).tz(moment.tz.guess()).format('LLL')}
                      </span>
                    </p>
                    <Divider />
                  </div>
                  {hasDocuments && <p className="big mt-4 font-semibold">Source</p>}
                  {logDetails.workspace_type === MIRROR && (
                    <div class="flex items-center justify-center">
                      <BookMarkDoc name={mirrorDetails?.document_a?.title} />
                      <BookMarkDoc name={mirrorDetails?.document_a?.title} />
                    </div>
                  )}
                  {logDetails.workspace_type === LOOP && (
                    <div class="flex items-center justify-center">
                      <BookMarkDoc name={extractorDetails?.document?.title} />
                    </div>
                  )}
                  {logDetails.workspace_type === ASSISTANT && (
                    <div className="flex items-center justify-center">
                      {threadDetails?.documents.map((item) => (
                        <BookMarkDoc key={item.id} name={item.title} />
                      ))}
                    </div>
                  )}
                  <div className=" flex flex-col">
                    <strong className="small mt-3">Output</strong>
                    {logDetails.workspace_type === MIRROR &&
                      mirrorDetails?.questions.map((item, index) => (
                        <BookMarkOutput key={index} prompt={item.data} response={item.response} />
                      ))}
                    {logDetails.workspace_type === 'ASSISTANT' &&
                      threadMessages.map((item, msgIndex) => (
                        <BookMarkOutput
                          key={item.id}
                          prompt={item.role === 'user' ? item.data : null}
                          response={item.role === 'assistant' ? item.data : null}
                        />
                      ))}

                    {logDetails.workspace_type === LOOP && (
                      <div class="flex items-center justify-center">
                        <ExtractorBookMarkOutput data={extractorDetails} />
                      </div>
                    )}
                  </div>

                  {/* <div className="w-full h-full border mt-3 border-gray rounded-xl ">
                                        <div className="flex justify-start  items-center gap-2 m-3">
                                            <div className=" ">
                                                <img
                                                    className="w-7"
                                                    alt=""
                                                    src={`${process.env.PUBLIC_URL}/icons/user.png`}
                                                />
                                            </div>

                                            <h2 className="small mx-3 text-gray font-semibold flex justify-start w-full">
                                                Original Text
                                            </h2>

                                            <button onClick={() => copyToClipboard(textForCopy)}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                    className="h-5 w-7"
                                                    alt=""
                                                />
                                            </button>

                                            <button
                                                className="h-3 mb-3 flex "
                                                type="button"
                                                onClick={() => setOriginalOpen(!originalOpen)}
                                            >
                                                {originalOpen ? (
                                                    <img
                                                        className="h-[22px] w-8"
                                                        alt=""
                                                        src={`${process.env.PUBLIC_URL}/icons/up.png`}
                                                    />
                                                ) : (
                                                    <img
                                                        className="h-[22px] w-8"
                                                        alt=""
                                                        src={`${process.env.PUBLIC_URL}/icons/down.png`}
                                                    />
                                                )}
                                            </button>
                                        </div>
                                        {originalOpen && <p className="m-3 mt-5 small">{textForCopy}</p>}
                                    </div> */}

                  {/* <div className="w-full h-full border mt-3 border-gray rounded-xl bg-purple-100">
                                        <div className="flex items-center gap-2 m-3">
                                            <div className=" rounded-full ">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/icons/ai.png`}
                                                    className="w-7 "
                                                    alt=""
                                                />
                                            </div>

                                            <h2 className="small mx-3 text-gray font-semibold w-1/2">AI Generated</h2>
                                            <div className="flex justify-end w-full gap-2">
                                                <button onClick={() => copyToClipboard(textForCopy)}>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/copy.png`}
                                                        className="h-5 w-5"
                                                        alt=""
                                                    />
                                                </button>
                                                <button
                                                    className="h-1 mb-3 "
                                                    type="button"
                                                    onClick={() => setAiOpen(!aiOpen)}
                                                >
                                                    {aiOpen ? (
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/icons/up.png`}
                                                            className="h-[22px]"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/icons/down.png`}
                                                            className="h-[22px]"
                                                            alt=""
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {aiOpen && <p className="m-3 mt-5 small">{textForCopy}</p>}
                                    </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
