import React from 'react'

export default function MirrorTutorialModal({ setShowModal }) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-2 mx-auto max-w-2xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between px-5 py-3 border-b border-solid border-blueGray-200 rounded-t">
              <h5 className=" text-gray-600 big">Mirror Tutorial</h5>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <h1 className="font-semibold text-[22px]">Here's a quick guide:</h1>
              <p className="my-2 mt-4 text-[#575757] text-[14px] leading-relaxed">
                1. 📁 File Upload : Use the File upload section to select and upload two PDF files for comparison.
              </p>
              <p className="my-2 text-[#575757] text-[14px] leading-relaxed">
                2. ⚙️ Choose Questions: In the "Configuration" section, select predefined questions relevant to your
                comparison or enter custom questions.
              </p>
              <p className="my-2 text-[#575757] text-[14px] leading-relaxed">
                3. 🔄 Start Comparison: Click "Start the Comparation" to process your files and questions.
              </p>
              <p className="my-2 text-[#575757] text-[14px] leading-relaxed">
                4. 🤖 Review Results: View the AI's responses to your questions, detailing comparisons, key points,
                structural changes, and contradictions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
