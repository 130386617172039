export const mapper = (data) => {
  return {
    name: `${data.document.title}-${Date.now()}`,
    instructions: data.attention,
    configurations: {
      goal: data.goal,
      length: data.length,
      result_format: data.format,
      language: data.language
    },
    document: data.document.id
  }
}
