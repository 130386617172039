import { DefaultModal } from 'components/shared/Modal/DefaultModal'

export const LoopTutorialModal = ({ setShowModal, showModal }) => {
  return (
    <DefaultModal title="Loop Tutorial" isVisible={showModal} onClose={() => setShowModal(false)}>
      <h1 className="text-[22px] font-semibold">Here's a quick guide:</h1>
      <p className="my-2 mt-4 text-[14px] leading-relaxed text-[#575757]">
        1. 📁 File Upload : Use the sidebar to upload a PDF file OR provide a URL from the EUR-Lex website.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        2. ⚙️ Choose Processor: Select the processing method based on your file's structure.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        3. 🔄 Start Processing: Click on the Continue button.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        4. 🤖 Extractor: Provide instructions, choose the model, activate context augmentation (optional), and set the
        temperature. Click Start to begin analysis.
      </p>
      <p className="my-2 text-[14px] leading-relaxed text-[#575757]">
        5. 💾 Download & Bookmark: Save the results as a PDF and Excel file, or bookmark them to view later on the
        bookmark page.
      </p>
    </DefaultModal>
  )
}
