import { ReactTooltip } from 'components/shared/Tooltip/ReactTooltip'
import { twJoin } from 'tailwind-merge'

export const Select = ({ options = [], label = '', iconSrc = '', iconInfoTooltip = false, register = {} }) => {
  return (
    <div className={twJoin(!!label && 'flex flex-col gap-2')}>
      {!!label && (
        <label className="flex gap-1">
          <span>{label}</span>
          <ReactTooltip tooltipContent={iconInfoTooltip} place="top" enabled={!!iconInfoTooltip}>
            {!!iconSrc && <img src={iconSrc} alt={iconInfoTooltip} />}
          </ReactTooltip>
        </label>
      )}

      {options.length > 0 && (
        <select className="w-full cursor-pointer rounded-md bg-gray-100 p-3" {...register}>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>
      )}
    </div>
  )
}
