// navigationSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeButton: ''
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActiveButton: (state, action) => {
      state.activeButton = action.payload
    }
  }
})

export const { setActiveButton } = navigationSlice.actions
export default navigationSlice.reducer
