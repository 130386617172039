import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LoopTutorialModal } from './components/LoopTutorialModal'
import { MirrorTutorialModal } from './components/MirrorTutorialModal'

export const TutorialModal = () => {
  const { pathname } = useLocation()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        className="m-2 flex rounded bg-[#F5F6FA] px-2 py-2 text-sm hover:bg-blue-100"
        onClick={() => {
          setShowModal(true)
        }}
      >
        <img className="mr-2 h-5 w-5" src="/icons/tutorial.png" alt="logo" />
        <p className="big">Tutorials</p>
      </button>

      <MirrorTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/mirror')} />
      <LoopTutorialModal setShowModal={setShowModal} showModal={showModal && pathname.includes('/loop')} />
    </>
  )
}

//TODO: Review this modal with Miguel
/*  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
              <div className="relative mx-auto my-2 w-auto max-w-4xl">
                
                <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                  <div className="border-blueGray-200 flex items-center justify-between rounded-t border-b border-solid px-5 py-3">
                    {pathname.includes('/loop') && (
                      <h5 className=" big text-gray-600">Knowledge Extractor Tutorials</h5>
                    )}
                    {pathname.includes('/mirror') && <h5 className=" big text-gray-600">Mirror Tutorials</h5>}
                    {(pathname.includes('/assistant') || pathname === '/playground') && (
                      <h5 className=" big text-gray-600">Tutorial</h5>
                    )}
                    <button
                      className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="block h-6 w-6 bg-transparent text-2xl text-black outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <div className="relative flex-auto p-6">
                    <h1 className="text-[24px] font-bold">🚀 Welcome to the AI Smart Assistant</h1>
                    <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
                      This application allows you to interact with files in a completely new and exciting way. Here, you
                      can ask questions about your files and our AI assistant will provide you with answers based on the
                      content of your files. It's like having a personal assistant for your data!
                    </p>
                    <h1 className="text-[22px] font-bold">📁 How to Select or Upload Files</h1>
                    <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
                      1. Select Files: On the left panel, you'll see a section called '🤖 Select file 📂'. Here you can
                      select the files you want to interact with. Just check the box next to the file names and you're
                      all set!
                    </p>
                    <p className="text-[14px]">
                      2. Upload Files: Can't see the files you need? No problem, you can upload your own files. Just go
                      to the '📁 FILES' section and click on 'Upload file'. Once your file is uploaded, click 'Upload
                      📎' to add it to the list of available files.
                    </p>
                    <p></p>
                    <h1 className="mt-4 text-[22px] font-bold">💬 How to Interact with the Assistant</h1>
                    <p className="text-blueGray-500 my-4 text-[14px] leading-relaxed">
                      Once you've selected or uploaded your files, you can start asking questions to the assistant.
                      Simply type your question into the chat box at the bottom of the main page and press 'Enter'. Our
                      AI assistant will process your question and provide you with an answer based on the content of
                      your files.
                    </p>
                  </div>

                </div>
              </div>
            </div> */
