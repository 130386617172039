import { twMerge } from 'tailwind-merge'
export const TextareaWithButton = ({
  ref,
  msg = '',
  isLoading = false,
  handleOnChange = () => {},
  handleOnClick = () => {}
}) => {
  const isDisabledButton = isLoading || msg === ''

  return (
    <div className="mx-13 justify-center items-center w-4/5 flex border rounded-lg shadow-md">
      <div className="container">
        <textarea
          disabled={isLoading}
          ref={ref}
          className={`centered-input w-full focus:outline-none small active:no-underline ${
            !msg?.trim() || msg?.split('\n')?.length === 1 ? 'center' : ''
          } ${isLoading ? 'text-gray-400' : ''}`}
          value={msg}
          onChange={(e) => {
            e.preventDefault()
            handleOnChange(e.target.value)
          }}
          placeholder="How can I help you?"
          rows={1}
          style={{
            maxHeight: '70px',
            overflowY: msg?.split('\n')?.length > 4 ? 'auto' : 'hidden'
          }}
        />
      </div>
      <div className="flex">
        <button
          disabled={isDisabledButton}
          onClick={() => {
            handleOnClick()
          }}
        >
          <div
            className={twMerge(
              'py-2 px-5 flex align-center justify-center rounded-md mr-2 my-2',
              isDisabledButton
                ? 'bg-gray-100 text-gray-400'
                : 'bg-gradient-to-r from-purple-500 to-purple-700 text-white'
            )}
          >
            <img
              className="h-4 w-4 mr-1"
              src={`${isDisabledButton ? '/icons/grayStar' : '/icons/whiteStar'}.png`}
              alt="Generate"
            />
            <p className="ml-1 big">{isLoading ? 'Processing...' : 'Generate'}</p>
          </div>
        </button>
      </div>
    </div>
  )
}
