import * as api from '../index.js'
export const createExtractor = (text, document) => async (dispatch) => {
  try {
    const data = {
      document: document.id,
      instructions: text
    }
    const response = await api.createExtractor(data)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const updateExtractor = (extractorid, text) => async (dispatch) => {
  try {
    const data = {
      instructions: text
    }

    const response = await api.updateExtractor(extractorid, data)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
export const getResult = (extractorid) => async (dispatch) => {
  try {
    const response = await api.getResult(extractorid)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
export const getSingleExtractor = (extractorid) => async (dispatch) => {
  try {
    const response = await api.getSingleExtractor(extractorid)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }

    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}

export const downloadPDF = (extractorid) => async (dispatch) => {
  try {
    const response = await api.downloadPDF(extractorid, { responseType: 'blob' });

    if (response.status < 300) {
      return { status: response.status, blob: response.data };
    } else {
      return { status: response.status, error: "Failed to download PDF" };
    }
  } catch (error) {
    if (error.response) {
      return { error: error.response.data.message ?? error.message, status: error.response.status ?? 400 };
    } else {
      const e = error.toJSON ? error.toJSON() : { message: error.message, error_code: 400 };
      return { error: e.message, status: e.error_code };
    }
  }
}
