import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { resetmirrorState } from 'app/features/mirrorSlice'
import { resetsmartchatState } from 'app/features/smartChatSlice'
import { resetLoop } from 'app/features/loopSlice'

const CleanData = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleReset = () => {
    const { pathname } = location

    // Use if-else or switch statements based on pathname
    if (pathname.includes('/loop')) {
      dispatch(resetLoop())
    } else if (pathname.includes('/mirror')) {
      dispatch(resetmirrorState())
    } else if (pathname.includes('/assistant') || pathname === '/playground') {
      dispatch(resetsmartchatState())
    } else {
      // Handle other cases or default action
    }

    // Close the confirmation modal after reset
    setShowConfirmation(false)
  }

  const handleCancel = () => {
    setShowConfirmation(false)
  }

  const showConfirmationModal = () => {
    setShowConfirmation(true)
  }

  return (
    <>
      <button
        onClick={showConfirmationModal}
        className="m-2 flex items-center rounded border bg-white px-4 py-2 text-[#555555] hover:bg-blue-100"
      >
        <img className="mr-2 h-5 w-5" src="/icons/eraser.png" alt="logo" />
        <span>
          <p className="big">Reset</p>
        </span>
      </button>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="rounded-lg bg-white p-8">
            <p className="mb-4 p-6 text-lg">Pressing Continue will clear the work area</p>
            <div className="flex justify-center pt-4">
              <button onClick={handleCancel} className="big m-2 cursor-pointer rounded border px-5 py-1 font-semibold">
                Cancel
              </button>
              <button
                onClick={handleReset}
                className="big m-2 cursor-pointer rounded bg-blue-600 px-5 py-1 font-semibold text-white"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CleanData
