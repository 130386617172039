import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Answer } from './Answer'

export const AnswerList = () => {
  const { generatedResponses } = useSelector((state) => state.mirror)
  const [expandedItems, setExpandedItems] = useState({})

  const toggleExpand = (answerId, isExpanded) => {
    setExpandedItems((prev) => ({ ...prev, [answerId]: isExpanded }))
  }

  return (
    <div className="text-[#5F5978] h-full w-full">
      {generatedResponses.map((answer, i) => (
        <div key={i} className="w-full px-5 mb-2">
          <div className="px-4 py-2 overflow-y-auto border mb-4 border-[#E7E7E7] rounded-lg bg-[#F6F4FF]">
            <Answer content={answer} isExpanded={expandedItems[answer.id]} toggleExpand={toggleExpand} />
          </div>
        </div>
      ))}
    </div>
  )
}
