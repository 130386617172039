import { deleteDocument, downloadDocument, getDocuments, uploadDocument } from 'api/Document/action/document'
import { Header } from 'components/shared/Headers/Header'
import { DefaultModal } from 'components/shared/Modal/DefaultModal'
import { UploadModal } from 'components/shared/Modal/UploadModal'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const Files = () => {
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const fetchDocuments = async () => {
    const response = await dispatch(getDocuments())
    if (response && Array.isArray(response.data.documents) && !response.error) {
      setFiles(response.data.documents)
    } else {
      setFiles([])
    }
  }

  useEffect(() => {
    fetchDocuments()
  }, [dispatch])

  const [searchQuery, setSearchQuery] = useState('')
  const [showModal, setShowModal] = useState(false)

  const filteredFiles = files.filter((file) => file.title.toLowerCase().includes(searchQuery.toLowerCase()))

  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [fileId, setFileId] = useState(null)

  const handleDelete = (id) => {
    setIsVisibleModal(true)
    setFileId(id)
  }

  const deleteFile = () => {
    const body = {
      id: fileId
    }
    dispatch(deleteDocument(body)).then((response) => {
      if (response.status === 204 && !response.error) {
        setFiles(files.filter((file) => file.id !== fileId))
      }
    })
  }

  const handleDownload = async (name, type, id) => {
    try {
      const data = await dispatch(downloadDocument({ id }))
      const blob = new Blob([data.data], { type: type })

      if (blob) {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${name}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url) // Limpia la URL después de descargar
      }
    } catch (error) {
      console.error('Error downloading document:', error)
    }
  }

  const handleFileUpload = (file, source, callback) => {
    const formData = new FormData()

    if (source === 'FILE_UPLOAD') {
      formData.append('file', file)
    } else if (source === 'EURLEX_URL') {
      formData.append('url', file)
    }
    formData.append('source', source)
    setIsLoading(true)

    dispatch(uploadDocument(formData))
      .then((response) => {
        callback(response)
        fetchDocuments().then(() => {
          setShowModal(false)
        })
      })
      .finally(() => {
        setIsLoading(false)
        setShowModal(false)
      })
  }

  const getFileIcon = (mimeType) => {
    switch (mimeType) {
      case 'application/pdf':
        return '/icons/pdf.png'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '/icons/word.png'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '/icons/xml.png'
      case 'text/html':
        return '/icons/html.png'
      default:
        return '/icons/not-found.png'
    }
  }
  return (
    <div>
      <Header heading="Files" icon="/icons/folder.png" />

      <div className="flex items-center justify-center p-1 ">
        <div className="relative flex w-full items-center">
          <input
            type="text"
            placeholder="Search by file name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-lg  border-gray-300 p-2 pl-9 active:outline-none"
          />
          <img
            src="/icons/search.png"
            alt="icon"
            className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 transform"
          />
        </div>

        <button
          onClick={() => setShowModal(true)}
          className="ml-4 flex items-center justify-center rounded bg-blue-500 px-8 py-2 text-white hover:bg-blue-600"
        >
          <img src="/icons/updown.png" alt="icon" className="mr-1  h-5 w-5" />
          <p className="">Upload</p>
        </button>
      </div>
      <div className="p-1 py-2 ">
        <div className="flex flex-wrap gap-4">
          {filteredFiles.map((file) => (
            <div key={file.id} className="   ">
              <div className="flex h-[15rem] w-[14.85rem] flex-col items-center rounded-lg border border-gray-200 bg-white p-3">
                <img src={getFileIcon(file.mime_type)} alt={`${file.type} icon`} className="mb-2 h-20 w-20" />
                <span className=" wrapped-text h-[6rem] w-full overflow-hidden pt-2 text-center text-sm">
                  {file.title}
                </span>

                <div className="mt-auto flex">
                  <button
                    onClick={() => handleDelete(file.id)}
                    className="big min-w-[82px] rounded-lg border bg-white px-2 py-1 text-sm text-gray-700 shadow transition-shadow duration-300 hover:bg-red-100"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => handleDownload(file.title, file.mime_type, file.id)}
                    className="big ml-2 min-w-[82px] rounded-lg border px-2 py-1 text-sm text-blue-700 shadow transition-shadow duration-300 hover:bg-blue-100"
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <UploadModal
        handleFileUpload={handleFileUpload}
        setShowModal={setShowModal}
        isLoading={isLoading}
        isVisible={showModal}
      />
      <DefaultModal
        size="s"
        isVisible={isVisibleModal}
        onClose={() => {
          setIsVisibleModal(false)
          setFileId(null)
        }}
        onSubmit={() => {
          deleteFile()
          setIsVisibleModal(false)
          setFileId(null)
        }}
        title="Delete File"
      >
        <span>You are going to delete this file. Do you want to continue?</span>
      </DefaultModal>
    </div>
  )
}

export default Files
