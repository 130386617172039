import { useCallback } from 'react'

export const useRandomID = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length

  const generateRandomID = useCallback(
    (length = 3) => {
      let result = ''
      const randomValues = crypto.getRandomValues(new Uint32Array(length))

      randomValues.forEach((value) => {
        result += characters.charAt(value % charactersLength)
      })

      return result
    },
    [characters, charactersLength]
  )

  return { generateRandomID }
}
