import { Contents } from 'pages/playground/components/Contents'
import { Header } from 'pages/playground/components/Header'
import { Tasks } from 'pages/playground/components/Tasks'

export const PlaygroundCard = ({ mainContent, title }) => {
  return (
    <div className="flex h-full w-full min-w-[500px] flex-col">
      <div className="flex text-xl">
        <img className="h-6" src="/icons/playground.png" alt="playground logo" />
        <h1 className="ml-2 font-bold">{title}</h1>
      </div>

      <div className="mt-3 flex grow flex-col rounded-md bg-white shadow-md">
        <Header />

        <div className="mx-2 mb-4 mt-2 flex grow">
          <div className="flex w-72 shrink-0 flex-col justify-between border-r pl-3 pr-4">
            <Contents />
            <Tasks />
          </div>

          <div className="flex grow flex-col">{mainContent}</div>
        </div>
      </div>
    </div>
  )
}
