// BookMarkOutput.jsx

import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styles from '../../../styles/Markdown.module.css'
import { useCopyText } from '../../../hooks/useCopyText'

const BookMarkOutput = ({ prompt, response, index }) => {
  const { copyToClipboard } = useCopyText()

  return (
    <React.Fragment key={index}>
      {/* Render the prompt section if prompt is not null */}
      {prompt && (
        <div className="w-full mb-4 overflow-auto">
          <div className="px-2 mx-3 my-2 overflow-auto rounded-[0.75rem] bg-[#FBFBFB] pr-3 pt-2">
            <div className="flex my-2 mr-10">
              <div className="flex gap-1 w-full ">
                <div className="items-start align-top justify-start">
                  <button className="bg-[#EAEAEA] border w-10 p-2 mx-2 rounded-lg">
                    <img className="w-5" src="/icons/user.png" alt="logo" />
                  </button>
                </div>

                <div>
                  <p className="text-[#575757] leading-[1rem] wrapped-text text-justify">{prompt}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Render the response section if response is not null */}
      {response && (
        <div className="w-full mb-4 overflow-y-auto">
          <div className="px-2 mx-3 overflow-auto rounded-[0.75rem] bg-[#F6F4FF] pr-3 pt-2">
            <div className="flex flex-col justify-between my-2">
              <div className="flex gap-1">
                <div className="items-start align-top justify-start">
                  <button className="bg-[#DFD7FE] border p-2 mx-2 w-10 rounded-lg">
                    <img className="w-5" src="/icons/ai.png" alt="logo" />
                  </button>
                </div>
                <div>
                  <p className="text-[#5F5978] leading-[1rem] wrapped-text text-justify">
                    <ReactMarkdown className={styles.markdown} remarkPlugins={[remarkGfm]}>
                      {response}
                    </ReactMarkdown>
                  </p>
                </div>
              </div>
              <button className="m-2 mx-16" onClick={() => copyToClipboard(response)}>
                <img className="h-5 w-5" src="/icons/chatcopy.png" alt="Copy to clipboard" />
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default BookMarkOutput
