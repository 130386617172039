import { twMerge } from 'tailwind-merge'

export const GenerateButton = ({ isLoading, isDisabled, onClick = () => {} }) => {
  return (
    <button
      disabled={isDisabled}
      onClick={() => {
        onClick()
      }}
    >
      <div
        className={twMerge(
          'flex justify-center gap-1 rounded-md px-3 py-2 text-sm',
          isDisabled ? 'bg-gray-100 text-gray-400' : 'bg-gradient-to-r from-purple-500 to-purple-700 text-white'
        )}
      >
        <img className="size-4" src={`${isDisabled ? '/icons/grayStar' : '/icons/whiteStar'}.png`} alt="Generate" />
        <p>{isLoading ? 'Processing...' : 'Generate'}</p>
      </div>
    </button>
  )
}
