import * as api from '../index.js'

export const getLogs = () => async (dispatch) => {
  try {
    const response = await api.getLogs()
    return response
  } catch (error) {
    return error
  }
}
export const getSingleLog = (id) => async (dispatch) => {
  try {
    const response = await api.getSingleLog(id)
    if (response.status < 300) return { status: response.status, data: response.data }
  } catch (error) {
    if (error.response) return { data: error.response.data.message ?? error.message, status: error.status ?? 400 }
    const e = error.toJSON()
    return { data: e.message, status: e.error_code ?? 400 }
  }
}
