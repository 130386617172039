import * as api from '../index.js'
export const getDocuments = () => async (dispatch) => {
  try {
    const response = await api.documentList()
    return response
  } catch (error) {
    return error
  }
}

export const downloadDocument = (body) => async (dispatch) => {
  try {
    const response = await api.documentDownload(body)
    return response
  } catch (error) {
    return error
  }
}

export const uploadDocument = (body) => async (dispatch) => {
  try {
    const response = await api.documentUpload(body)
    return response
  } catch (error) {
    return error
  }
}

export const deleteDocument = (body) => async (dispatch) => {
  try {
    const response = await api.documentDelete(body)
    return response
  } catch (error) {
    return error
  }
}
